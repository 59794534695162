export const ScuSwitchIcon = (props) => {
    return (
        <svg
            width="30"
            height="24"
            viewBox="0 0 15 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
                border:  props.currentAgentStatus === 'volume' ? '1px solid #379dd9 ' : '1px solid #ededed',
                padding: '5px',
                borderRadius: '8px',
                boxShadow: props.currentAgentStatus === 'volume' ? 'none' : '1px 2px #d8d8d8',
                backgroundColor: props.currentAgentStatus === 'volume' ? '#379dd9' : 'transparent',
            }}
        >
            <path
                d="M13.875 5.75H11.25V1.625C11.25 1.01562 10.7344 0.5 10.125 0.5H4.875C4.24219 0.5 3.75 1.01562 3.75 1.625V5.75H1.125C0.492188 5.75 0 6.26562 0 6.875V11.375C0 12.0078 0.492188 12.5 1.125 12.5H13.875C14.4844 12.5 15 12.0078 15 11.375V6.875C15 6.26562 14.4844 5.75 13.875 5.75ZM4.875 1.625H6.375V3.75781L7.5 3.26562L8.625 3.75781V1.625H10.125V5.75H4.875V1.625ZM6.9375 11.375H1.125V6.875H3V9.00781L4.125 8.51562L5.25 9.00781V6.875H6.9375V11.375ZM13.875 11.375H8.0625V6.875H9.75V9.00781L10.875 8.51562L12 9.00781V6.875H13.875V11.375Z"
                fill={props.fill}/>
            />
        </svg>
    )
}
