import { ReloadIcon } from 'assets/icons/AppIcons/ReloadIcon';
import {
	IconButton,
	MapBox,
	PageTitle,
	SearchBox,
	SwitchBox,
} from 'components';
import { DriverItem } from 'components/DriverItem/DriverItem';
import { PageView } from 'components/PageView/PageView';
import { RightModalDriver } from 'components/RightModalDriver/RightModalDriver';
import { SwitchTransportBox } from 'components/SwitchTransportBox/SwitchTransportBox';
import { TransportItem } from 'components/TransportItem/TransportItem';
import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDrivers } from 'utils/api/driver';
import { AccordionBoxAgent } from '../../components/AccordionBoxAgent/AccordionBoxAgent';
import { RightModalTransport } from '../../components/RightModalTransport/RightModalTransport';
import { SwitchAgentBox } from '../../components/SwitchAgentBox/SwitchAgentBox';
import { getAllCustomers } from '../../utils/api/customer';
import { getAllTransports } from '../../utils/api/transport';
import './DirectoryPage.scss';

const navInputs = [
	{
		status: 'counterparties',
		text: 'контрагенты',
	},
	{
		status: 'drivers',
		text: 'водители',
	},
	{
		status: 'transport',
		text: 'транспорт',
	},
];

const transportStatus = [
	{
		status: 'all',
		text: 'Все',
	},
	{
		status: 'car',
		text: 'car',
		value: '0',
	},
	{
		status: 'truck',
		text: 'truck',
		value: '1',
	},
	{
		status: 'bike',
		text: 'bike',
		value: '2',
	},
	{
		status: 'human',
		text: 'human',
		value: '3',
	},
];

const agentStatus = [
	{
		status: 'all',
		text: 'Все',
	},
	{
		status: 'sum',
		text: 'sum',
		value: '1',
	},
	{
		status: 'volume',
		text: 'volume',
		value: '2',
	},
];

const DirectoryPage = () => {
	const dispatch = useDispatch();
	const allTransports = useSelector((state) => state.transport.data.transports);
	const allCustomers = useSelector((state) => state.customer.data.customers);
	const sessions = useSelector((state) => state?.sessions.data);
	const driversList = useSelector((state) => state.driver.data);
	const [currentStatus, setCurrentStatus] = useState('counterparties');
	const [currentTransportStatus, setCurrentTransportStatus] = useState('all');
	const [currentAgentStatus, setCurrentAgentStatus] = useState('all');
	const [visibleDriverModal, setVisibleDriverModal] = useState(false);
	const [visibleTransportModal, setVisibleTransportModal] = useState(false);
	const [dataDriver, setDataDriver] = useState({});
	const [dataTransport, setDataTransport] = useState({});

	useEffect(() => {
		dispatch(getAllDrivers());
		dispatch(getAllTransports());
		dispatch(getAllCustomers());
	}, [currentTransportStatus]);
	// console.log('DirectoryPage Customer', allCustomers);
	const handlerDriver = (data) => {
		setDataDriver(data);
		setVisibleDriverModal(true);
	};

	const handlerTransport = (data) => {
		// console.log(data);
		setDataTransport(data);
		setVisibleTransportModal(true);
	};
	const onHideDriverModal = () => setVisibleDriverModal(false);
	const onHideTransportModal = () => setVisibleTransportModal(false);

	const filterTransportByStatus = () => {
		if (currentTransportStatus === 'all') {
			return allTransports;
		} else {
			return allTransports.filter((transport) => {
				switch (currentTransportStatus) {
					case 'car':
						return transport.transportType === 0;
					case 'truck':
						return transport.transportType === 1;
					case 'bike':
						return transport.transportType === 2;
					case 'human':
						return transport.transportType === 3;
					default:
						return false;
				}
			});
		}
	};

	const filterAgentByStatus = () => {
		if (currentAgentStatus === 'all') {
			return allCustomers;
		} else {
			return allCustomers.filter((customer) => {
				switch (currentAgentStatus) {
					case 'sum':
						return allCustomers
							.slice()
							.sort((a, b) => b.totalOrdersSum - a.totalOrdersSum);
					case 'volume':
						return allCustomers
							.slice()
							.sort((a, b) => b.totalOrdersVolume - a.totalOrdersVolume);
					default:
						return 'No customers found';
				}
			});
		}
	};

	return (
		<PageView>
			<RightModalDriver
				visibleModalDriver={visibleDriverModal}
				titleCloseButton='ВОДИТЕЛЬ / ЭКСПЕДИТОР'
				onHideDriverModal={onHideDriverModal}
				dataDriver={dataDriver}
				allTransports={allTransports}
				sessions={sessions}
			/>
			<RightModalTransport
				visibleModalTransport={visibleTransportModal}
				titleCloseButton='ТРАНСПОРТ'
				onHideTransportModal={onHideTransportModal}
				dataTransport={dataTransport}
				allDrivers={driversList}
			/>
			<PageTitle title='Справочники' />
			<SwitchBox
				status={navInputs}
				active={currentStatus}
				onChangeStatus={setCurrentStatus}
			/>
			<div className='status-data'>
				<Card
					className='orders-data mt-4'
					style={{ width: '50%', marginRight: 10 }}
				>
					<Card.Header>
						<div className='search-box'>
							<SearchBox />
							<IconButton
								icon={<ReloadIcon />}
								className='search-box-icon-btn'
							/>
						</div>
						{/* <FilterBox /> */}
						{/* <RouteBox btnTitle="сформировать маршруты" /> */}
						{currentStatus === 'transport' && (
							<>
								<SwitchTransportBox
									count={allTransports.length}
									statusTransport={transportStatus}
									activeTransportStatus={currentTransportStatus}
									onChangeTransportStatus={setCurrentTransportStatus}
								/>
							</>
						)}
						{currentStatus === 'counterparties' && (
							<>
								<SwitchAgentBox
									count={allCustomers.length}
									statusAgent={agentStatus}
									activeAgentStatus={currentAgentStatus}
									onChangeAgentStatus={setCurrentAgentStatus}
								/>
							</>
						)}
					</Card.Header>
					{/* <Card.Body> */}
					<div style={{ overflowY: 'scroll' }}>
						{currentStatus === 'counterparties' && (
							<>
								<AccordionBoxAgent allCustomers={filterAgentByStatus()} />
							</>
						)}

						{currentStatus === 'drivers' && (
							<>
								{driversList.map((el) => (
									<DriverItem key={el.Id} data={el} onClick={handlerDriver} />
								))}
							</>
						)}
						{currentStatus === 'transport' && (
							<>
								{filterTransportByStatus().length > 0 ? (
									filterTransportByStatus().map((el) => (
										<TransportItem
											key={el.Id}
											data={el}
											onClick={handlerTransport}
										/>
									))
								) : (
									<p>No transports found</p>
								)}
							</>
						)}
					</div>
					{/* </Card.Body> */}
				</Card>
				<Card className='mt-4' style={{ width: '50%' }}>
					<MapBox />
				</Card>
			</div>
		</PageView>
	);
};

export default DirectoryPage;
