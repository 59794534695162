import { DownloadIcon } from 'assets/icons/AppIcons/DownloadIcon';
import { ReloadIcon } from 'assets/icons/AppIcons/ReloadIcon';
import { ReverseIcon } from 'assets/icons/AppIcons/ReverseIcon';
import { IconButton } from 'components';
import { Button } from 'react-bootstrap';

import './RouteBox.scss';

export const RouteBox = (props) => {
	return (
		<div className='route-box'>
			<Button className='route-box-btn' onClick={props.onClick}>
				{props.btnTitle}
			</Button>
			<IconButton icon={<DownloadIcon />} className='route-box-btn' />
			<IconButton icon={<ReloadIcon />} className='route-box-btn' />
			<IconButton icon={<ReverseIcon />} className='route-box-btn' />
			<Button
				className='add-transport-button'
				onClick={props.visibleModalTransports}
			>
				Подобрать машины
			</Button>
		</div>
	);
};
