import { CardTransportMap } from 'components/CardTransportMap/CardTransportMap';
import { CloseButton } from 'components/CloseButton/CloseButton';
import { MapBox } from 'components/MapBox/MapBox';
import { useEffect, useState } from 'react';
import './RightModalMap.scss';

export const RightModalMap = (props) => {
	const [activeTransport, setActiveTransport] = useState(null);
	useEffect(() => {
		dataActiveTransport();
	}, [props]);

	// получение объекта выбранного транспорта, для отображения на карте
	const dataActiveTransport = () => {
		props.sessions?.map((el) => {
			if (
				el.transport.Id === props.idTransport &&
				el.indexSession === props.indexSession
			) {
				setActiveTransport(el);
			}
		});
		if (props.idTransport.length === 0) {
			setActiveTransport(null);
		}
	};

	return (
		<div className={props.visible ? 'right-modal' : 'hide-modal'}>
			<CloseButton
				onClick={() => props.onHide()}
				className='close-btn'
				titleCloseButton={props.titleCloseButton}
			/>
			<div className='right-modal-content right-modal-content-map'>
				<CardTransportMap data={activeTransport} />
				<MapBox
					className='right-modal-map'
					idTransport={props.idTransport}
					sessions={props.sessions}
					orders={props.orders}
					addOrderTransport={props.addOrderTransport}
					removeOrderTransport={props.removeOrderTransport}
					allPoints={props.allPoints}
				/>
			</div>
		</div>
	);
};
