export const RoadIcon = () => {
    return (
        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.9375 8.375H7.6875C7.14844 8.375 6.75 7.97656 6.75 7.4375C6.75 6.92188 7.14844 6.5 7.6875 6.5H9.75C9.75 6.5 12 3.99219 12 2.75C12 1.50781 10.9922 0.5 9.75 0.5C8.50781 0.5 7.5 1.50781 7.5 2.75C7.5 3.45312 8.20312 4.55469 8.8125 5.375H7.6875C6.53906 5.375 5.625 6.3125 5.625 7.4375C5.625 8.58594 6.53906 9.5 7.6875 9.5H9.9375C10.4531 9.5 10.875 9.92188 10.875 10.4375C10.875 10.9766 10.4531 11.375 9.9375 11.375H3.16406C3.77344 10.5547 4.5 9.45312 4.5 8.75C4.5 7.50781 3.49219 6.5 2.25 6.5C1.00781 6.5 0 7.50781 0 8.75C0 9.99219 2.25 12.5 2.25 12.5H9.9375C11.0625 12.5 12 11.5859 12 10.4375C12 9.3125 11.0625 8.375 9.9375 8.375ZM8.625 2.75C8.625 2.14062 9.11719 1.625 9.75 1.625C10.3594 1.625 10.875 2.14062 10.875 2.75C10.8516 3.07812 10.3828 3.89844 9.75 4.74219C9.09375 3.89844 8.625 3.07812 8.625 2.75ZM2.25 10.7422C1.59375 9.89844 1.125 9.07812 1.125 8.75C1.125 8.14062 1.61719 7.625 2.25 7.625C2.85938 7.625 3.375 8.14062 3.375 8.75C3.35156 9.07812 2.88281 9.89844 2.25 10.7422ZM2.25 8.375C2.03906 8.375 1.875 8.5625 1.875 8.75C1.875 8.96094 2.03906 9.125 2.25 9.125C2.4375 9.125 2.625 8.96094 2.625 8.75C2.625 8.5625 2.4375 8.375 2.25 8.375ZM10.125 2.75C10.125 2.5625 9.9375 2.375 9.75 2.375C9.53906 2.375 9.375 2.5625 9.375 2.75C9.375 2.96094 9.53906 3.125 9.75 3.125C9.9375 3.125 10.125 2.96094 10.125 2.75Z"
                fill="#7A7A7A"
            />
        </svg>
    )
}
