import { createBrowserHistory } from 'history';

import { configureStore } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

import customerReducer from './slices/customerSlice';
import sessionsReducer from './slices/deliverySessionsSlice';
import driverReducer from './slices/driverSlice';
import orderReducer from './slices/orderSlice';
import transportReducer from './slices/transportSlice';
import userReducer from './slices/userSlice';
import zoneReducer from './slices/zoneSlice';

export const history = createBrowserHistory();

const rootReducer = (history) => ({
	user: userReducer,
	customer: customerReducer,
	order: orderReducer,
	driver: driverReducer,
	transport: transportReducer,
	sessions: sessionsReducer,
	zone: zoneReducer,
	router: connectRouter(history),
});

const preloadedState = {};
const store = configureStore({
	middleware: [thunk, routerMiddleware(history)],
	reducer: rootReducer(history),

	preloadedState,
});

export default store;
