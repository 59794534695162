import { ProfileIcon } from 'assets/icons/NavIcons/ProfileIcon';
import { FormInput, PageTitle } from 'components';
import { useEffect, useState } from 'react';
import { Button, Card, Form, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { removeUser } from 'store/slices/userSlice';
import './ProfilePage.scss';

const ProfilePage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const user = useSelector((state) => state?.user?.data?.user);
	const [avatar, setAvatar] = useState(user?.avatar || '');
	const [lastName, setLastName] = useState(user?.lastName || '');
	const [firstName, setFirstName] = useState(user?.firstName || '');
	const [secondName, setSecondName] = useState(user?.secondName || '');
	const [email, setEmail] = useState(user?.email || '');
	const [phone, setPhone] = useState(user?.phone || '');

	const handlerLastName = (e) => setLastName(e.target.value);
	const handlerFirstName = (e) => setFirstName(e.target.value);
	const handlerSecondName = (e) => setSecondName(e.target.value);
	const handlerEmail = (e) => setEmail(e.target.value);
	const handlerPhone = (e) => setPhone(e.target.value);

	const onPhotoChange = (e) => {
		if (e.target.files && e.target.files[0]) {
			let img = e.target.files[0];
			setAvatar(URL.createObjectURL(img));
		}
	};

	useEffect(() => {
		if (user === undefined) {
			dispatch(removeUser());
			navigate('/sign-in');
		}
	}, []);

	// useEffect

	// const token = localStorage.getItem('AccessToken');

	// console.log('localStorage ProfilePage', token);

	return (
		<div>
			<PageTitle title='Профиль пользователя' />
			<Card className='p-5' style={{ width: 800 }}>
				<Form className='d-flex flex-column mb-5'>
					<FormInput
						label='Фамилия'
						placeholder='Введите фамилию'
						type='text'
						value={lastName}
						onChange={handlerLastName}
					/>
					<FormInput
						label='Имя'
						placeholder='Введите имя'
						type='text'
						value={firstName}
						onChange={handlerFirstName}
					/>
					<FormInput
						label='Отчество'
						placeholder='Введите отчество'
						type='text'
						value={secondName}
						onChange={handlerSecondName}
					/>
					<FormInput
						label='E-mail'
						placeholder='Введите E-mail'
						type='email'
						value={email}
						onChange={handlerEmail}
					/>
					<FormInput
						label='Мобильный'
						placeholder='Введите мобильный телефон'
						type='tel'
						pattern='[0-9]{3}-[0-9]{2}-[0-9]{3}'
						value={phone}
						onChange={handlerPhone}
					/>
				</Form>
				<div className='avatar-box'>
					{avatar ? (
						<Image
							className='avatar-box-image'
							src={avatar}
							width={122}
							height={122}
						/>
					) : (
						<ProfileIcon
							className='avatar-box-image'
							width={122}
							height={122}
						/>
					)}
					<label className='label'>
						<input
							type='file'
							className='input-type-file'
							onChange={onPhotoChange}
						/>
						добавить изображение
					</label>
				</div>
			</Card>
			<div className='action'>
				<Button className='action__add-btn'>В машину</Button>
				<Button className='action__delete-btn'>Из машины</Button>
			</div>
		</div>
	);
};

export default ProfilePage;
