export const ReverseIcon = () => {
    return (
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.125 6.25H7.125C7.00781 6.25 6.9375 6.34375 6.9375 6.4375V6.8125C6.9375 6.92969 7.00781 7 7.125 7L9.16406 7.02344L6.98438 10.0938C6.96094 10.1641 6.9375 10.2578 6.9375 10.3281V10.5625C6.9375 10.6797 7.00781 10.75 7.125 10.75H10.125C10.2188 10.75 10.3125 10.6797 10.3125 10.5625V10.1875C10.3125 10.0938 10.2188 10 10.125 10H8.0625L10.2422 6.92969C10.2656 6.85938 10.2891 6.76562 10.3125 6.69531V6.4375C10.3125 6.34375 10.2188 6.25 10.125 6.25ZM10.4766 4.375L9.07031 0.4375C9.02344 0.34375 8.90625 0.273438 8.8125 0.25H8.41406C8.32031 0.273438 8.20312 0.34375 8.15625 0.4375L6.75 4.375C6.75 4.42188 6.75 4.44531 6.75 4.49219C6.75 4.63281 6.86719 4.75 7.00781 4.75H7.28906C7.38281 4.75 7.5 4.67969 7.54688 4.5625L7.89844 3.53125H9.32812L9.67969 4.5625C9.72656 4.67969 9.84375 4.75 9.9375 4.75H10.2188C10.3594 4.75 10.4766 4.63281 10.4766 4.49219C10.4766 4.44531 10.4766 4.42188 10.4766 4.375ZM8.13281 2.80469C8.13281 2.80469 8.60156 1.46875 8.60156 1.39844C8.625 1.46875 9.09375 2.80469 9.09375 2.80469H8.13281ZM4.64062 8.21875C4.59375 8.17188 4.52344 8.14844 4.45312 8.14844C4.38281 8.14844 4.28906 8.17188 4.26562 8.21875L3 9.46094V0.4375C3 0.34375 2.90625 0.25 2.8125 0.25H2.4375C2.32031 0.25 2.25 0.34375 2.25 0.4375V9.4375L0.984375 8.21875C0.9375 8.17188 0.84375 8.14844 0.773438 8.14844C0.703125 8.14844 0.632812 8.17188 0.585938 8.21875L0.445312 8.35938C0.398438 8.40625 0.351562 8.47656 0.351562 8.54688C0.351562 8.61719 0.398438 8.71094 0.445312 8.75781L2.41406 10.6797C2.46094 10.7266 2.55469 10.7734 2.60156 10.7734C2.67188 10.7734 2.76562 10.7266 2.8125 10.6797L4.78125 8.75781C4.82812 8.71094 4.875 8.61719 4.875 8.54688C4.875 8.47656 4.82812 8.40625 4.78125 8.35938L4.64062 8.21875Z"
                fill="black"
            />
        </svg>
    )
}
