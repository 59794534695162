import { NavBar } from 'components';
import PageLayout from 'layout/PageLayout/PageLayout';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './BaseLayout.scss';

const BaseLayout = ({ children }) => {
	const navigate = useNavigate();
	const isLogin = useSelector((state) => state.user.isLogin);

	// console.log(isLogin);

	useEffect(() => {
		if (isLogin === false) {
			navigate('/sign-in');
		}
	}, [isLogin]);

	return (
		<div className='base'>
			<NavBar />
			<PageLayout>{children}</PageLayout>
		</div>
	);
};

export default BaseLayout;
