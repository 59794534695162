export const CloseIcon = (props) => {
	return (
		<svg
			width={props.width ? props.width : '20'}
			height={props.height ? props.height : '20'}
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M12.3086 9.75L18.3438 3.77344L19.5742 2.54297C19.75 2.36719 19.75 2.07422 19.5742 1.83984L18.2852 0.550781C18.0508 0.375 17.7578 0.375 17.582 0.550781L10.375 7.81641L3.10938 0.550781C2.93359 0.375 2.64062 0.375 2.40625 0.550781L1.11719 1.83984C0.941406 2.07422 0.941406 2.36719 1.11719 2.54297L8.38281 9.75L1.11719 17.0156C0.941406 17.1914 0.941406 17.4844 1.11719 17.7188L2.40625 19.0078C2.64062 19.1836 2.93359 19.1836 3.10938 19.0078L10.375 11.7422L16.3516 17.7773L17.582 19.0078C17.7578 19.1836 18.0508 19.1836 18.2852 19.0078L19.5742 17.7188C19.75 17.4844 19.75 17.1914 19.5742 17.0156L12.3086 9.75Z'
				fill={props.fill ? props.fill : 'white'}
			/>
		</svg>
	);
};
