export const WeightIcon = (props) => {
	return (
		<svg
			width='14'
			height='13'
			viewBox='0 0 14 13'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M12.9531 10.9531L11.2422 4.10938C11.1484 3.75781 10.8438 3.52344 10.5156 3.52344H8.80469C8.96875 3.21875 9.0625 2.91406 9.0625 2.58594C9.0625 1.4375 8.125 0.523438 7 0.523438C5.85156 0.523438 4.9375 1.4375 4.9375 2.58594C4.9375 2.91406 5.00781 3.21875 5.17188 3.52344H3.46094C3.13281 3.52344 2.82812 3.75781 2.73438 4.10938L1.02344 10.9531C0.835938 11.75 1.375 12.5 2.125 12.5H11.8516C12.6016 12.5 13.1406 11.75 12.9531 10.9531ZM6.0625 2.5625C6.0625 2.04688 6.46094 1.625 7 1.625C7.51562 1.625 7.9375 2.04688 7.9375 2.5625C7.9375 3.10156 7.51562 3.5 7 3.5C6.46094 3.5 6.0625 3.10156 6.0625 2.5625ZM11.8281 11.375H2.14844C2.125 11.375 2.10156 11.3047 2.125 11.2344L3.76562 4.625H10.2109L11.8516 11.2344C11.875 11.3047 11.8516 11.375 11.8281 11.375Z'
				fill={props.fill ? props.fill : '#7A7A7A'}
			/>
		</svg>
	);
};
