import { axios } from 'core';
import {
	setAllTransports,
	setSessions,
} from 'store/slices/deliverySessionsSlice';
import { removeUser } from 'store/slices/userSlice';
import { getOrdersList } from './order';

const correctData = (str) => {
	return str.length < 2 ? '0' + str : str;
};

const getTomorow = (time) => {
	let today = new Date();

	let tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);

	return (
		tomorrow.getFullYear() +
		'-' +
		correctData((tomorrow.getMonth() + 1).toString()) +
		'-' +
		correctData(tomorrow.getDate().toString()) +
		' ' +
		time
	);
};

const date = {
	startDate: getTomorow('00:00:05'),
	endDate: getTomorow('23:59:59'),
};

const allTransportSessions = (arr) => {
	let res = JSON.parse(JSON.stringify(arr));
	return res.sessions?.concat(res.freeTransports);
};

const createIndexSession = (arr) => {
	let res = JSON.parse(JSON.stringify(arr));
	res?.sessions?.map((el, index) => (el.indexSession = index));
	return res.sessions;
};

export const getDeliverySessionsByDate = () => {
	let access_token = localStorage.getItem('AccessToken');

	return async (dispatch) => {
		try {
			const res = await axios.get(
				`/logistics/delivery/sessions?startDate=${date.startDate}&endDate=${date.endDate}`,

				{
					headers: {
						Authorization: `${access_token}`,
					},
				}
			);

			dispatch(setAllTransports(allTransportSessions(res.data)));
			dispatch(setSessions(createIndexSession(res.data)));
			return;
		} catch (error) {
			console.log(
				'error getDeliverySessionsByDate',
				error.response.data.message
			);
			if (error.response.data.message === 'invalid Access token') {
				dispatch(removeUser());
			}
		}
	};
};

export const createDeliverySessions = (data) => {
	let access_token = localStorage.getItem('AccessToken');
	let sessions = { sessions: data };

	return async (dispatch) => {
		try {
			const res = await axios.post(
				`/logistics/delivery/sessions/add`,
				sessions,
				{
					headers: {
						Authorization: `${access_token}`,
					},
				}
			);

			if (res.status === 200) {
				// Promise.all([
				// 	dispatch(getOrdersList()),
				// 	dispatch(getDeliverySessionsByDate()),
				// ]);
				dispatch(getOrdersList());
				dispatch(getDeliverySessionsByDate());
				alert('Маршруты добавлены!');

				// dispatch(getOrdersList());
			}
			// console.log('res createDeliverySessions', res);
		} catch (error) {
			if (error.response.data.message === 'invalid Access token') {
				dispatch(removeUser());
			}
			alert(`Ошибка отправки! ${error}`);
			console.log('error', error.response);
		}
	};
};
