import { axios } from 'core';
import { setZones } from 'store/slices/zoneSlice';

export const getAllDeliveryZones = () => {
	let access_token = localStorage.getItem('AccessToken');

	return async (dispatch) => {
		try {
			const res = await axios.get(
				`/logistics/delivery/zones/all`,

				{
					headers: {
						Authorization: `${access_token}`,
					},
				}
			);

			if (res.data.code === 200) dispatch(setZones(res.data.zones));
		} catch (error) {
			console.log('error', error);
		}
	};
};
