import React from "react"

import "./CarNumberBox.scss"

export const CarNumberBox = (props) => {
    return (
        <div className="car-number-box">
            <span>{props.carNumber}</span>
        </div>
    )
}
