import { ExitIcon } from 'assets/icons/HeaderIcons/ExitIcon';
import { HamburgerIcon } from 'assets/icons/HeaderIcons/HamburgerIcon';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { removeUser } from 'store/slices/userSlice';
import Clock from './Clock/Clock';
import DateHeader from './DateHeader/DateHeader';

import { Link, useNavigate } from 'react-router-dom';
import './Header.scss';
import UserMenu from './UserMenu/UserMenu';

export const Header = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [visibleUserMenu, setVisibleUserMenu] = useState(false);

	const logOut = () => {
		localStorage.removeItem('AccessToken');
		dispatch(removeUser());
		navigate('/sign-in');
	};

	const handlerUserMenu = () => setVisibleUserMenu(!visibleUserMenu);

	return (
		<div className='header'>
			<div className='header__left'>
				<div className='header__left-item'></div>
				<div className='header__left-item'>
					<Clock />
					<DateHeader />
				</div>
				<Link className='header__left-item' onClick={logOut}>
					<ExitIcon />
				</Link>
			</div>
			<div className='header__right'>
				<Link onClick={handlerUserMenu}>
					<HamburgerIcon />
				</Link>
				<UserMenu visible={visibleUserMenu} />
				{/* <div className='header__right'></div> */}
			</div>
		</div>
	);
};
