import { WeightIcon } from 'assets/icons/AppIcons/WeightIcon';

import { ListIcon } from 'assets/icons/AppIcons/ListIcon';
import './TransportDataBox.scss';

export const TransportDataBox = (props) => {
	return (
		<div className='box-transport'>
			<div style={{ display: 'flex', flexDirection: 'row' }}>
				<div className='box_item'>
					<WeightIcon />
					<label className='box_item-text'>{props.data.loadCapacity} </label>
				</div>
				<div className='box_item'>
					<ListIcon />
					<label className='box_item-text'>{props.counterSession}</label>
				</div>
			</div>
			{props.activity && <label>{props.activity}</label>}
		</div>
	);
};
