export const DownloadIcon = () => {
    return (
        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.5938 10.625C10.5938 10.3672 10.3828 10.1562 10.125 10.1562C9.86719 10.1562 9.65625 10.3672 9.65625 10.625C9.65625 10.8828 9.86719 11.0938 10.125 11.0938C10.3828 11.0938 10.5938 10.8828 10.5938 10.625ZM8.625 10.1562C8.36719 10.1562 8.15625 10.3672 8.15625 10.625C8.15625 10.8828 8.36719 11.0938 8.625 11.0938C8.88281 11.0938 9.09375 10.8828 9.09375 10.625C9.09375 10.3672 8.88281 10.1562 8.625 10.1562ZM12 9.03125C12 8.46875 11.5312 8 10.9688 8H8.625L9.96094 6.6875C10.5469 6.10156 10.125 5.09375 9.28125 5.09375H8.0625V1.4375C8.0625 0.921875 7.64062 0.5 7.125 0.5H4.875C4.33594 0.5 3.9375 0.921875 3.9375 1.4375V5.09375H2.69531C1.85156 5.09375 1.42969 6.10156 2.03906 6.6875L3.35156 8H1.03125C0.445312 8 0 8.46875 0 9.03125V11.4688C0 12.0547 0.445312 12.5 1.03125 12.5H10.9688C11.5312 12.5 12 12.0547 12 11.4688V9.03125ZM5.85938 9.45312L2.55469 6.14844C2.4375 6.03125 2.53125 5.84375 2.69531 5.84375H4.6875V1.4375C4.6875 1.34375 4.75781 1.25 4.875 1.25H7.125C7.21875 1.25 7.3125 1.34375 7.3125 1.4375V5.84375H9.28125C9.44531 5.84375 9.53906 6.03125 9.42188 6.14844L6.11719 9.45312C6.04688 9.52344 5.92969 9.52344 5.85938 9.45312ZM11.25 9.03125V11.4688C11.25 11.6328 11.1094 11.75 10.9688 11.75H1.03125C0.867188 11.75 0.75 11.6328 0.75 11.4688V9.03125C0.75 8.89062 0.867188 8.75 1.03125 8.75H4.10156L5.32031 9.99219C5.69531 10.3438 6.28125 10.3438 6.65625 9.99219L7.875 8.75H10.9688C11.1094 8.75 11.25 8.89062 11.25 9.03125Z"
                fill="black"
            />
        </svg>
    )
}
