import { AmountIcon } from 'assets/icons/AppIcons/AmountIcon';
import { RubIcon } from 'assets/icons/AppIcons/RubIcon';
import { ScuIcon } from 'assets/icons/AppIcons/ScuIcon';
import { WeightIcon } from 'assets/icons/AppIcons/WeightIcon';

import './OrderDataBox.scss';

export const OrderDataBox = (props) => {
	const data = props.data;

	// console.log('data OrderDataBox', data);

	return (
		<div className='box'>
			<div className='box_item'>
				<RubIcon />
				<label className='box_item-text'>{data.totalSum}</label>
			</div>
			<div className='box_item'>
				<ScuIcon />
				<label className='box_item-text'>{data.totalSCU + ' SCU'}</label>
			</div>
			<div className='box_item'>
				<WeightIcon />
				<label className='box_item-text'>{data.totalWeight + ' кг'}</label>
			</div>
			<div className='box_item'>
				<AmountIcon />
				<label className='box_item-text'>{data.totalAmount + ' шт'}</label>
			</div>
		</div>
	);
};
