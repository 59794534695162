import { CarIcon } from 'assets/icons/AppIcons/CarIcon';
import { CarNumberBox } from 'components/CarNumberBox/CarNumberBox';
import { DriverDataBox } from 'components/DriverDataBox/DriverDataBox';
import './CardTransportMap.scss';

export const CardTransportMap = (props) => {
	const data = props.data;
	// console.log('props CardTransportMap', props);
	return (
		<div className='card-transport-map-content'>
			{data !== null ? (
				<>
					<div className='card-transport-map-content-title'>
						<CarIcon fill={data.transport.traceColor} />
						<span>{data.driver.driverName}</span>
						<CarNumberBox carNumber={data.transport.licenseId} />
						<span className='model-car'>{data.transport.transportModel}</span>
					</div>
					<DriverDataBox data={data} />{' '}
				</>
			) : (
				<div className='card-transport-map-content-title'>
					<span className='model-car'>Машина не выбрана</span>
				</div>
			)}
		</div>
	);
};
