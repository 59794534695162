	import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GenPassIcon } from 'assets/icons/AppIcons/GenPassIcon';
import { CloseButton } from 'components/CloseButton/CloseButton';
import { FormInput } from 'components/FormInput/FormInput';
import { FormSelect } from 'components/FormSelect/FormSelect';
import { IconButton } from 'components/IconButton/IconButton';
import { useEffect, useState } from 'react';
import {Button, Card, Form, Image} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import { updateDriver } from 'utils/api/driver';
import './RightModalDriver.scss';
	import {ProfileIcon} from "../../assets/icons/NavIcons/ProfileIcon";

export const RightModalDriver = (props) => {
	const dispatch = useDispatch();
	const [driverName, setDriverName] = useState('');
	const [phone, setPhone] = useState('');
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [email, setEmail] = useState('');
	const [transport, setTransport] = useState({});
	const [city, setCity] = useState('');
	const [isRoleMenuOpen, setIsRoleMenuOpen] = useState(false);
	const [selectedRole, setSelectedRole] = useState(props.dataDriver?.forwarder);
	const driver = useSelector((state) => state?.driver?.data?.driver);
	const [avatar, setAvatar] = useState(driver?.avatar || '');

	useEffect(() => {
		setDriverName(props.dataDriver?.driverName);
		setPhone(props.dataDriver?.phone);
		setPassword(props.dataDriver?.password);
		setEmail(props.dataDriver?.email);
		setTransport(props.dataDriver?.transport);
		setCity(props.dataDriver?.city);
		setSelectedRole(props.dataDriver?.forwarder);
	}, [props]);

	const handlerDriverName = (e) => setDriverName(e.target.value);

	const handlerPhone = (e) => setPhone(e.target.value);

	const handlerPassword = (e) => setPassword(e.target.value);

	const handlerEmail = (e) => setEmail(e.target.value);

	const handlerTransport = (e) => {
		setTransport(e.target.value);
	};

	const handlerCity = (e) => setCity(e.target.value);

	const assignTransportToDriver = () => {
		dispatch(
			updateDriver({
				Id: props.dataDriver.Id,
				city,
				driverName,
				email,
				forwarder: selectedRole,
				password,
				phone,
				transportId: transport.Id,
			})
		);
		props.onHideDriverModal();
	};

	const handlerCheckTransport = (val) => {
		setTransport(val);
	};

	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handlerGenPassword = () => {
		let password = Math.random().toString(36).slice(-8);
		setShowPassword(true);
		setPassword(password);
		setTimeout(() => {
			setShowPassword(false);
		}, 1500);
	};

	const toggleRoleMenu = () => {
		setIsRoleMenuOpen(!isRoleMenuOpen);
	};

	const handleRoleSelection = (role) => {
		setSelectedRole(role);
		setIsRoleMenuOpen(false);
	};

	const onPhotoChange = (e) => {
		if (e.target.files && e.target.files[0]) {
			let img = e.target.files[0];
			setAvatar(URL.createObjectURL(img));
		}
	};

	const hideDriverModal = () => {
		setSelectedRole(props.dataDriver?.forwarder);
		setIsRoleMenuOpen(false);
		props.onHideDriverModal();
	};

	return (
		<div className={props.visibleModalDriver ? 'right-modal' : 'hide-modal'}>
			<CloseButton
				onClick={hideDriverModal}
				className='close-btn'
				titleCloseButton={props.titleCloseButton}
			/>
			<div className='right-modal-content right-modal-content-driver'>
				<Card style={{width: '100%', height: 400, padding: 45}}>
					<Form className='d-flex flex-column mb-5 form-block'>
						<div className='form-select-container'>
							<div className=
									 'custom-select-default' onClick={toggleRoleMenu}>
								{ selectedRole === false ? 'Водитель' : 'Экспедитор'}
								<div className="arrow">&#8250;</div>
								<div className="line"></div>
							</div>
							{isRoleMenuOpen && (
								<div className='role-menu'>
									<div className='option' onClick={() => handleRoleSelection(false)}>Водитель</div>
									<div className='option' onClick={() => handleRoleSelection(true)}>Экспедитор</div>
								</div>
							)}
						</div>
						<FormInput
							label='ФИО'
							placeholder='Введите Фамилию, Имя и Отчество'
							type='text'
							value={driverName}
							onChange={handlerDriverName}
						/>
						<FormInput
							label='Телефон'
							placeholder='Введите телефон'
							type='tel'
							pattern='[0-9]{3}-[0-9]{2}-[0-9]{3}'
							value={phone}
							onChange={handlerPhone}
						/>
						<div className='password-container'>
							<FormInput
								className='password-input'
								label='Пароль'
								placeholder='Введите пароль'
								type={showPassword ? 'text' : 'password'}
								value={password}
								onChange={handlerPassword}
							/>
							<FontAwesomeIcon
								icon={showPassword ? faEyeSlash : faEye}
								className='show-password-icon'
								onClick={toggleShowPassword}
							/>
							<IconButton
								icon={<GenPassIcon/>}
								className='gen-pass-icon'
								onClick={handlerGenPassword}
							/>
						</div>
						<FormInput
							label='E-mail'
							placeholder='Введите E-mail'
							type='email'
							value={email}
							onChange={handlerEmail}
						/>
						<FormSelect
							label='Транспорт'
							placeholder='Выберите транспорт'
							type='text'
							allTransports={props.allTransports}
							// sessions={props.sessions}
							value={transport?.licenseId}
							onChange={handlerTransport}
							handlerCheckTransport={handlerCheckTransport}
						/>
						<FormInput
							label='Предпочитаемый город'
							placeholder='Введите предпочитаемый город'
							type='text'
							value={city}
							onChange={handlerCity}
						/>
						<div className='avatar-box'>
							{avatar ? (
								<Image
									className='avatar-box-image'
									src={avatar}
									width={122}
									height={122}
								/>
							) : (
								<ProfileIcon
									className='avatar-box-image'
									width={122}
									height={122}
								/>
							)}
							<label className='label'>
								<input
									type='file'
									className='input-type-file'
									onChange={onPhotoChange}
								/>
								добавить изображение
							</label>
						</div>
					</Form>

				</Card>
				<div className='action'>
					<Button className='action__add-btn' onClick={assignTransportToDriver}>
						Сохранить
					</Button>
					<Button
						className='action__delete-btn'
						// onClick={deleteTransportToDriver}
					>
						Отмена
					</Button>
				</div>
			</div>
		</div>
	);
};
