import {RubIcon} from "../../assets/icons/AppIcons/RubIcon";
import {ScuIcon} from "../../assets/icons/AppIcons/ScuIcon";
import {useState} from "react";
import {RadioInput} from "../RadioInput/RadioInput";
import './SwitchAgentBox.scss'
import {ScuSwitchIcon} from "../../assets/icons/SwitchAgentIcon/ScuSwitchIcon";
import {RubSwitchIcon} from "../../assets/icons/SwitchAgentIcon/RubSwitchIcon";


export const SwitchAgentBox = (props) => {
    const [value, setValue] = useState(props.activeAgentStatus);
    const handlerChange = (value) => {
        setValue(value);
        props.onChangeAgentStatus(value);
        console.log('status current', value);
    }

    return(
        <div className='switch-agent-box-wrapper'>
            {props?.statusAgent?.map((el, idx) => (
                <RadioInput
                    key={idx}
                    value={el.status}
                    text={
                        <div className='agent-icons'>
                            <div>
                                {el.status === 'all' && (
                                    <div className={`agent-icons-all ${value === 'all' ? 'all-active' : ''}`}>
                                        <span
                                            className={`agent-icons-all-text  ${value === 'all' ? 'text-active' : ''}`}>{el.text}</span>
                                        <span
                                            className={`agent-icons-all-count  ${value === 'all' ? 'count-active' : ''}`}>{props.count}</span>
                                    </div>
                                )}
                            </div>
                            {el.status === 'sum' && (
                                <RubSwitchIcon
                                    currentAgentStatus={value}  
                                    fill={value === 'sum' ? '#ffff' : 'black'}
                                    onClick={() => handlerChange('sum')}
                                />
                            )}
                            {el.status === 'volume' && (
                                <ScuSwitchIcon
                                    currentAgentStatus={value}
                                    fill={value === 'volume' ? '#ffff' : 'black'}
                                    onClick={() => handlerChange('volume')}
                                />
                            )}
                        </div>
                    }
                    onChange={() => handlerChange(el.status)}
                    checked={value === el.status}
                    badge={props.badge}
                    counter={el.counter}
                />
            ))}
        </div>
    );
}