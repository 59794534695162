import React, { useState } from "react"
import { Button, Card, Container, Form, Nav } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { signUp } from "utils/api/user"

import "./Auth.scss"

const SignUpForm = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [phone, setPhone] = useState("")

    const dispatch = useDispatch()

    const handlerAuth = () => {
        console.log(email, password, firstName, lastName, phone)
        dispatch(signUp(email, password, firstName, lastName, phone))
    }

    return (
        <Container
            className="d-flex justify-content-center align-items-center"
            style={{ height: window.innerHeight - 54 }}
        >
            <Card style={{ width: 600 }} className="p-5">
                <Nav className="justify-content-start flex-grow-1 pe-3 mb-5">
                    <Nav.Link href="/sign-in" className={["text-uppercase nav"]}>
                        Вход
                    </Nav.Link>
                    <Nav.Link href="/sign-up" className={["text-uppercase nav-active switch"]}>
                        Зарегистрироваться
                    </Nav.Link>
                </Nav>
                <Form className="d-flex flex-column">
                    <Form.Control
                        className="mt-3"
                        placeholder="Имя"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        type="text"
                    />
                    <Form.Control
                        className="mt-3"
                        placeholder="Фамилия"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        type="text"
                    />
                    <Form.Control
                        className="mt-3"
                        placeholder="E-mail"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="text"
                    />
                    <Form.Control
                        className="mt-3"
                        placeholder="Пароль"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                    />
                    <Form.Control
                        className="mt-3"
                        placeholder="Подтверждение пароля"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        type="text"
                    />

                    <Button onClick={handlerAuth} className="mt-5 uppercase button">
                        Зарегистрироваться
                    </Button>
                </Form>
            </Card>
        </Container>
    )
}

export default SignUpForm
