import React from "react"

import "./Badge.scss"

export const Badge = (props) => {
    return (
        <div
            className={
                props.active === true ? `badge-active ${props.className}` : ` badge-disabled  ${props.className}`
            }
        >
            {props.counter || 0}
        </div>
    )
}
