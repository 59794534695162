import { axios } from 'core';
import { setError, setUser } from 'store/slices/userSlice';
// import { getAllTransports } from "./transport"
// import { getAllDrivers } from "./driver"

export const signIn = (email, password) => {
	return async (dispatch) => {
		try {
			const res = await axios.post('/user/sign-in', { email, password });

			if (res.data.token) {
				localStorage.setItem('AccessToken', res.data.token);
				dispatch(setUser(res.data));
			}
		} catch (error) {
			console.log('error', error);
			dispatch(setError(true));
			return error.response.status;
		}
	};
};

export const signUp = (email, password, firstName, lastName, phone) => {
	return async () => {
		const res = await axios
			.post('/user/sign-up', { email, password, firstName, lastName, phone })
			.then((res) => res)
			.catch((e) => e.response.status);
		console.log('res', res);
	};
};
