import { RadioInput } from 'components';
import { useState } from 'react';
import {TruckIcon} from "../../assets/icons/AppIcons/TruckIcon";
import {PassengerCarIcon} from "../../assets/icons/AppIcons/PassengerCarIcon";
import {BikeIcon} from "../../assets/icons/AppIcons/BikeIcon";
import {HumanIcon} from "../../assets/icons/AppIcons/HumanIcon";

import './SwitchTransportBox.scss';

export const SwitchTransportBox = (props) => {
    const [value, setValue] = useState(props.activeTransportStatus);
    const handlerChange = (value) => {
        setValue(value);
        props.onChangeTransportStatus(value);
        console.log('status current', value);
    }

    return(
        <div className='switch-transport-box-wrapper'>
            {props?.statusTransport?.map((el, idx) => (
                <RadioInput
                    key={idx}
                    value={el.status}
                    text={
                        <div className='transport-icons'>
                            <div>
                                {el.status === 'all' && (
                                    <div className={`transport-icons-all ${value === 'all' ? 'all-active' : ''}`}>
                                        <span className={`transport-icons-all-text  ${value === 'all' ? 'text-active' : ''}`}>{el.text}</span>
                                        <span className={`transport-icons-all-count  ${value === 'all' ? 'count-active' : ''}`}>{props.count}</span>
                                    </div>
                                )}
                            </div>
                            {el.status === 'car' && (
                                <PassengerCarIcon
                                    currentTransportStatus={value}
                                    fill={value === 'car' ? '#ffff' : 'black'}
                                    onClick={() => handlerChange('car')}
                                />
                            )}
                            {el.status === 'truck' && (
                                <TruckIcon
                                    currentTransportStatus={value}
                                    fill={value === 'truck' ? '#ffff' : 'black'}
                                    onClick={() => handlerChange('truck')}
                                />
                            )}
                            {el.status === 'bike' && (
                                <BikeIcon
                                    currentTransportStatus={value}
                                    fill={value === 'bike' ? '#ffff' : 'black'}
                                    onClick={() => handlerChange('bike')}
                                />
                            )}
                            {el.status === 'human' && (
                                <HumanIcon
                                    currentTransportStatus={value}
                                    fill={value === 'human' ? '#ffff' : 'black'}
                                    onClick={() => handlerChange('human')}
                                />
                            )}
                        </div>
                    }
                    onChange={() => handlerChange(el.status)}
                    checked={value === el.status}
                    badge={props.badge}
                    counter={el.counter}
                />
            ))}
        </div>
    )
}