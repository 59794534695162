import React from "react"
import { Button } from "react-bootstrap"
import { FormInput } from "components"

import "./SearchBox.scss"

export const SearchBox = () => {
    return (
        <div className="search-box">
            <FormInput placeholder="Поиск" className="search-box-input" icon={true} />
            <Button className="mb-3 search-box-button">Найти</Button>
        </div>
    )
}
