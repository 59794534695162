import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	data: null,
	activeZone: '',
};

const zoneSlice = createSlice({
	name: 'zone',
	initialState,
	reducers: {
		setZones(state, action) {
			state.data = action.payload;
		},
		setActiveZone(state, action) {
			state.activeZone = action.payload;
		},
	},
});

export const { setZones, setActiveZone } = zoneSlice.actions;

export default zoneSlice.reducer;
