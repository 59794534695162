import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	data: null,
	allTransports: null,
};

const deliverySessionsSlice = createSlice({
	name: 'sessions',
	initialState,
	reducers: {
		setSessions(state, action) {
			state.data = action.payload;
		},
		clearSessions(state, action) {
			state.data = action.payload;
		},
		setAllTransports(state, action) {
			state.allTransports = action.payload;
		},
	},
});

export const { setSessions, clearSessions, setAllTransports } =
	deliverySessionsSlice.actions;

export default deliverySessionsSlice.reducer;
