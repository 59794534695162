import { CarIcon } from 'assets/icons/AppIcons/CarIcon';
import { MailIcon } from 'assets/icons/AppIcons/MailIcon';
import { PhoneIcon } from 'assets/icons/AppIcons/PhoneIcon';
import { RateIcon } from 'assets/icons/AppIcons/RateIcon';
import { CarNumberBox } from 'components/CarNumberBox/CarNumberBox';
import './DriverItem.scss';

export const DriverItem = (props) => {
	const data = props?.data;
	console.log('DriverItem data:', data.transport.transportModel);
	return (
		<div className='driver-item-wrapper' onClick={() => props.onClick(data)}>
			<div className='driver-item-info'>
				<div className='driver-item-header'>
					<CarIcon fill={data.transport.traceColor} />
					<div className='driver-item-grid'>
						<label>{data.driverName}</label>
					</div>
					<div className='driver-item-grid'>
						<CarNumberBox
							carNumber={
								data.transport.licenseId
									? data.transport.licenseId
									: 'НЕ НАЗНАЧЕН'
							}
						/>
					</div>
					<div className='driver-item-grid'>
						<span>{data.transport.transportModel}</span>
					</div>
				</div>
				<div className='driver-item-body'>
					{data.phone ? <PhoneIcon /> : null}
					<span>{data.phone ? '+' + data.phone : null}</span>
					{data.email ? <MailIcon className='driver-item-body-icon' /> : null}
					<span>{data.email ? data.email : null}</span>
				</div>
				<div className='driver-item-footer'>
					<RateIcon />
					<span>3,5</span>
				</div>
			</div>
			<div className='driver-item-photo-wrapper'>
				<div className='photo'></div>
				<label>В сети</label>
			</div>
		</div>
	);
};
