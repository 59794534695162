export const RoutesIcon = (props) => {
	return (
		<svg
			width='25'
			height='24'
			viewBox='0 0 50 48'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M48.0625 21.9375L27.0625 0.9375C26.5 0.375 25.75 0 25 0C24.1562 0 23.4062 0.375 22.8438 0.9375L1.84375 21.9375C0.625 23.0625 0.625 25.0312 1.84375 26.1562L22.8438 47.1562C23.4062 47.7188 24.1562 48 25 48C25.75 48 26.5 47.7188 27.0625 47.1562L48.0625 26.1562C49.2812 25.0312 49.2812 23.0625 48.0625 21.9375ZM24.9062 45L4 24L24.9062 3.09375C24.9062 3.09375 24.9062 3 25 3V3.09375L45.9062 24.0938L24.9062 45ZM31.9375 21H20.5C17.9688 21 16 23.0625 16 25.5V29.25C16 29.7188 16.2812 30 16.75 30H18.25C18.625 30 19 29.7188 19 29.25V25.5C19 24.75 19.6562 24 20.5 24H31.9375L26.875 28.7812C26.5938 29.0625 26.5 29.5312 26.7812 29.8125L27.8125 30.9375C28.0938 31.2188 28.5625 31.2188 28.8438 30.9375L36.25 24.1875C36.7188 23.8125 37 23.1562 37 22.5C37 21.9375 36.7188 21.2812 36.25 20.9062L28.8438 14.1562C28.5625 13.875 28.0938 13.875 27.8125 14.1562L26.7812 15.2812C26.5 15.5625 26.5938 16.0312 26.875 16.3125L31.9375 21Z'
				fill={props.fill}
			/>
		</svg>
	);
};
