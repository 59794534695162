import React from "react"

import "./PageTitle.scss"

export const PageTitle = (props) => {
    return (
        <div className={`title ${props.className}`}>
            <span>{props.title}</span>
        </div>
    )
}
