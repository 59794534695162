import { Accordion, Card } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { CarIcon } from 'assets/icons/AppIcons/CarIcon';
import { CarNumberBox } from 'components/CarNumberBox/CarNumberBox';
import { DriverDataBox } from 'components/DriverDataBox/DriverDataBox';
import { OrderDataBox } from 'components/OrderDataBox/OrderDataBox';
import { RouteBox } from 'components/RouteBox/RouteBox';
import { ViewingBox } from 'components/ViewingBox/ViewingBox';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveZone } from 'store/slices/zoneSlice';
import './AccordionBox.scss';

export const AccordionBox = (props) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const [activeGeo, setActiveGeo] = useState(false);
	const [idActiveGeo, setIdActiveGeo] = useState('');

	const allTransports = props?.sessions;
	const activeZone = useSelector((state) => state.zone.activeZone);

	const handlerClickGeo = (order) => {
		if (order.deliveryPoint.Id === idActiveGeo && activeGeo === true) {
			setActiveGeo(false);
			setIdActiveGeo('');
		} else {
			setIdActiveGeo(order.deliveryPoint.Id);
			setActiveGeo(true);
			props.visiblePoint(order);
		}
	};
	const handlerActiveZone = (id) => dispatch(setActiveZone(id));

	const observerActiveZone = () =>
		alert('Зона доставки не выбрана! Выберите зону доставки.');

	return (
		<Card className='orders-data'>
			{allTransports && (
				<Card.Header>
					{/* <SearchBox />
				<FilterBox /> */}
					{/* <DateFilterBox /> */}
					<RouteBox
						btnTitle={
							location.pathname === '/orders'
								? 'сформировать маршруты'
								: 'сохранить маршруты'
						}
						onClick={props.createSession}
						visibleModalTransports={
							activeZone
								? props.visibleModalTransports
								: () => observerActiveZone()
						}
					/>
				</Card.Header>
			)}
			<div className='accordion'>
				<Accordion>
					{props.allZones &&
						props.allZones.map((zone, i) => {
							return (
								<Accordion.Item eventKey={String(i)}>
									<Accordion.Header
										onClick={() => handlerActiveZone(zone.Id)}
										id={zone.Id}
									>
										<div className='accordion-header-content'>
											<div className='accordion-header-content-title'>
												<span>{zone.name}</span>
											</div>
										</div>
									</Accordion.Header>
									<Accordion.Body>
										<Accordion>
											{allTransports?.map((el, index) => {
												if (el.deliveryZone.Id === activeZone) {
													return (
														<Accordion.Item
															eventKey={String(index)}
															// key={el.transport.Id}
															// key={index}
														>
															<Accordion.Header
																onClick={() =>
																	props.onClick(
																		el.transport.Id,
																		el.indexSession
																	)
																}
																id={el.transport.Id}
															>
																<div className='accordion-header-content'>
																	<div className='accordion-header-content-title'>
																		<CarIcon fill={el.transport.traceColor} />
																		<span>{el.driver.driverName}</span>
																		<CarNumberBox
																			carNumber={el.transport.licenseId}
																		/>
																		<span className='model-car'>
																			{el.transport.transportModel}
																		</span>
																	</div>
																	<DriverDataBox
																		delTransport={(e) =>
																			props.delTransport(e, el)
																		}
																		data={el}
																		delButton={
																			el.Id.length === 0 ? true : false
																		}
																	/>
																</div>
															</Accordion.Header>
															<Accordion.Body>
																{el?.orders?.map((it, index) => {
																	return (
																		<Card
																			className='order-card w-100'
																			key={index}
																		>
																			<Card.Body className='order-card-body'>
																				{'Заказ №' + it.textOrderNumber + ','}{' '}
																				{it.deliveryPoint?.address + ', '}
																				{it.deliveryPoint?.companyName}
																			</Card.Body>
																			<Card.Footer className='order-card-footer'>
																				<OrderDataBox data={it} />
																				<ViewingBox
																					onClickOrder={() =>
																						props.visibleOrder(it)
																					}
																					onClickGeo={() => handlerClickGeo(it)}
																					activeGeo={
																						idActiveGeo === it.deliveryPoint?.Id
																							? activeGeo
																							: false
																					}
																				/>
																			</Card.Footer>
																		</Card>
																	);
																})}
															</Accordion.Body>
														</Accordion.Item>
													);
												}
											})}
										</Accordion>
									</Accordion.Body>
								</Accordion.Item>
							);
						})}
				</Accordion>
				{/* <Accordion>
					{allTransports?.map((el, index) => {
						return (
							<Accordion.Item
								eventKey={String(index)}
								// key={el.transport.Id}
								// key={index}
							>
								<Accordion.Header
									onClick={() =>
										props.onClick(el.transport.Id, el.indexSession)
									}
									id={el.transport.Id}
								>
									<div className='accordion-header-content'>
										<div className='accordion-header-content-title'>
											<CarIcon fill={el.transport.traceColor} />
											<span>{el.driver.driverName}</span>
											<CarNumberBox carNumber={el.transport.licenseId} />
											<span className='model-car'>
												{el.transport.transportModel}
											</span>
										</div>
										<DriverDataBox
											delTransport={(e) => props.delTransport(e, el)}
											data={el}
											delButton={el.Id.length === 0 ? true : false}
										/>
									</div>
								</Accordion.Header>
								<Accordion.Body>
									{el?.orders?.map((it, index) => {
										return (
											<Card className='order-card w-100' key={index}>
												<Card.Body className='order-card-body'>
													{'Заказ №' + it.textOrderNumber + ','}{' '}
													{it.deliveryPoint?.address + ', '}
													{it.deliveryPoint?.companyName}
												</Card.Body>
												<Card.Footer className='order-card-footer'>
													<OrderDataBox data={it} />
													<ViewingBox
														onClickOrder={() => props.visibleOrder(it)}
														onClickGeo={() => handlerClickGeo(it)}
														activeGeo={
															idActiveGeo === it.deliveryPoint?.Id
																? activeGeo
																: false
														}
													/>
												</Card.Footer>
											</Card>
										);
									})}
								</Accordion.Body>
							</Accordion.Item>
						);
					})}
				</Accordion> */}
			</div>
		</Card>
	);
};
