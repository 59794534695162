export const PlusIcon = (props) => {
	return (
		<svg
			width='7'
			height='7'
			viewBox='0 0 7 7'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M3.852 3.344H6.24V4.196H3.852V6.668H2.988V4.196H0.6V3.344H2.988V0.86H3.852V3.344Z'
				fill={props.fill ? props.fill : 'black'}
			/>
		</svg>
	);
};
