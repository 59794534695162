import React from 'react';
import { MailIcon } from 'assets/icons/AppIcons/MailIcon';
import { PhoneIcon } from 'assets/icons/AppIcons/PhoneIcon';
import {CarNumberBox} from "../CarNumberBox/CarNumberBox";
import {WeightIcon} from "../../assets/icons/AppIcons/WeightIcon";
import {TruckTransportIcon} from "../../assets/icons/TransportIcons/TruckTransportIcon";
import {CarTransportIcon} from "../../assets/icons/TransportIcons/CarTransportIcon";
import {BikeTransportIcon} from "../../assets/icons/TransportIcons/BikeTransportIcon";
import {HumanTransportIcon} from "../../assets/icons/TransportIcons/HumanTransportIcon";
import './TransportItem.scss'


export const TransportItem = (props) => {
    const data = props.data;
    return(
        <div className='transport-item-wrapper' onClick={()=>props.onClick(data)}>
            <div className="transport-item-header">
                {data?.transportType === 0 && <CarTransportIcon fill={data?.traceColor || 'none'}/>}
                {data?.transportType === 1 && <TruckTransportIcon fill={data?.traceColor || 'none'}/>}
                {data?.transportType === 2 && <BikeTransportIcon fill={data?.traceColor || 'none'}/>}
                {data?.transportType === 3 && <HumanTransportIcon fill={data?.traceColor || 'none'}/>}
                <div className='transport-item-grid'>
                    <CarNumberBox
                        carNumber={
                            data?.licenseId
                                ? data.licenseId
                                : 'НЕ НАЗНАЧЕН'
                        }
                    />
                    {/*<span className='transport-lisence'>E627X82</span>*/}
                </div>
                <div className='transport-item-grid'>
                    <span>{data?.transportModel || 'Модель не указана'}</span>
                    {/*<span>Мерседес-Бенц 410D</span>*/}
                </div>
                <div className='transport-item-weight'>
                    <WeightIcon/>
                    {/*<label className='transport-item-text'>{`3000 кг`} </label>*/}
                    <span className='transport-item-text'>{data?.loadCapacity} кг</span>
                </div>
                <div className='transport-item-volume'>
                    {/*<label className='transport-item-text'>{`V 2000 м3`} </label>*/}
                    <span className='transport-item-text'>V {data?.bulkSpace} м³</span>
                </div>
            </div>
            <div className="transport-item-body">
                <div className="photo"></div>
                <div className='transport-item-grid'>
                    <label>{data?.driver?.driverName}</label>
                    {/*<span>Иванов Иван</span>*/}
                </div>
            </div>
            <div className="transport-item-footer">
                <div className="transport-item-contacts">
                    <PhoneIcon/>
                    <span>{data.driver.phone ? '+' + data.driver.phone : 'Не указан'}</span>
                    {/*<PhoneIcon />*/}
                    {/*<span>+7 (111) 111-11-11</span>*/}
                    <MailIcon className='transport-item-body-icon' />
                    <span>{data.email ? data.driver.email : 'Не указан'}</span>
                    {/*<MailIcon className='transport-item-body-icon' />*/}
                    {/*<span>ivanov.i@ya.ru</span>*/}
                </div>
                <div className="transport-item-status">
                    <span>На маршруте</span>
                </div>
            </div>
        </div>
    );
}