import { CloseButton } from 'components/CloseButton/CloseButton';
import { MapBox } from 'components/MapBox/MapBox';
import './RightModalOrder.scss';

export const RightModalOrder = (props) => {
	// console.log('props.orderModal', props.orderModal);
	const order = props?.orderModal;

	const dateFormatter = (date) => {
		let res = new Date(date);

		let day =
			res.getDate() < 10
				? '0' + res.getDate().toString()
				: res.getDate().toString();
		let month =
			res.getMonth() + 1 < 10
				? '0' + (res.getMonth() + 1).toString()
				: (res.getMonth() + 1).toString();

		let year = res.getFullYear().toString();
		// setFormatDate(day + '.' + month + '.' + year);
		return day + '.' + month + '.' + year;
	};
	return (
		<div className={props.visible ? 'right-modal' : 'hide-modal'}>
			<CloseButton
				onClick={() => props.onHide()}
				className='close-btn'
				titleCloseButton={props.titleCloseButton}
			/>
			<div className='right-modal-content right-modal-content-order'>
				<div className='order-title'>
					<span>
						{`Заказ №${order?.textOrderNumber} от ${dateFormatter(
							order?.orderCreated
						)}`}
					</span>
				</div>
				<table style={{ width: '100%' }}>
					<tbody>
						<tr>
							<th className='info-th'>Клиент:</th>
							<td>{`${order?.deliveryPoint.companyName}`}</td>
						</tr>
						<tr>
							<th className='info-th'>Менеджер:</th>
							<td>{`${order?.manager}`}</td>
						</tr>
						<tr>
							<th className='info-th'>Адрес доставки:</th>
							<td>{`${order?.deliveryPoint.address}`}</td>
						</tr>
						<tr>
							<th className='info-th'>Сумма с НДС:</th>
							<td>{`${order?.totalSum}`}</td>
						</tr>
						<tr>
							<th className='info-th'>НДС:</th>
							<td>{`${order?.totalSumNds ? order?.totalSumNds : '--'}`}</td>
						</tr>
					</tbody>
				</table>
				<div className='contact-info'>
					<div className='contact-info-header'>
						<span className='contact-info-header-title'>
							Контактная информация
						</span>
						<hr className='contact-info-header-separator' />
					</div>
					<table style={{ width: '100%' }}>
						<tbody>
							<tr>
								<th className='info-th'>Юридический адрес:</th>
								<td>{`${order?.deliveryPoint.address}`}</td>
							</tr>
							<tr>
								<th className='info-th'>Телефоны:</th>
								<td>{`${
									order?.deliveryPoint.phone ? order?.deliveryPoint.phone : '--'
								}`}</td>
							</tr>
							<tr>
								<th className='info-th'>Электронная почта:</th>
								<td>{`${
									order?.deliveryPoint.email ? order?.deliveryPoint.email : '--'
								}`}</td>
							</tr>
							<tr>
								<th className='info-th'>Адрес доставки:</th>
								<td>{`${order?.deliveryPoint.address}`}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className='contact-info'>
					<div className='contact-info-header'>
						<span className='contact-info-header-title'>Товары</span>
						<hr className='contact-info-header-separator' />
					</div>
					<div className='contact-info-table'>
						<table>
							<thead>
								<tr>
									<th>№</th>
									<th className='contact-info-table-product'>Товар</th>
									<th>Количество</th>
									<th>Ед. изм.</th>
									<th>Цена</th>
									<th>Сумма</th>
									<th>Ставка НДС</th>
									<th>НДС</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>A1</td>
									<td>A2</td>
								</tr>
								<tr>
									<td>B1</td>
									<td>B2</td>
								</tr>
								<tr>
									<td>C1</td>
									<td>C2</td>
								</tr>
								<tr>
									<td>D1</td>
									<td>D2</td>
								</tr>
								<tr>
									<td>E1</td>
									<td>E2</td>
								</tr>
								<tr>
									<td>A1</td>
									<td>A2</td>
								</tr>
								<tr>
									<td>B1</td>
									<td>B2</td>
								</tr>
								<tr>
									<td>C1</td>
									<td>C2</td>
								</tr>
								<tr>
									<td>D1</td>
									<td>D2</td>
								</tr>
								<tr>
									<td>E1</td>
									<td>E2</td>
								</tr>
								<tr>
									<td>A1</td>
									<td>A2</td>
								</tr>
								<tr>
									<td>B1</td>
									<td>B2</td>
								</tr>
								<tr>
									<td>C1</td>
									<td>C2</td>
								</tr>
								<tr>
									<td>D1</td>
									<td>D2</td>
								</tr>
								<tr>
									<td>E1</td>
									<td>E2</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div className='contact-info'>
					<div className='contact-info-header'>
						<span className='contact-info-header-title'>
							Дополнительная информация
						</span>
						<hr className='contact-info-header-separator' />
					</div>
				</div>
				<MapBox className='right-order-map' point={order} findPoint={order} />
			</div>
		</div>
	);
};
