import BaseLayout from 'layout/BaseLayout/BaseLayout';
import SignInForm from 'pages/Auth/SignInForm';
import SignUpForm from 'pages/Auth/SignUpForm';
import DirectoryPage from 'pages/DirectoryPage/DirectoryPage';
import MapPage from 'pages/MapPage/MapPage';
import ProfilePage from 'pages/ProfilePage/ProfilePage';
import RoutesPage from 'pages/RoutesPage/RoutesPage';
import { useRoutes } from 'react-router-dom';
// import DesktopPage from "pages/DesktopPage/DesktopPage"
// import OrdersPage from "pages/OrdersPage/OrdersPage"
// import AnalyticsPage from "pages/AnalyticsPage/AnalyticsPage"

function AppRoutes() {
	const routes = useRoutes([
		{ path: '/sign-in', element: <SignInForm /> },
		{ path: '/sign-up', element: <SignUpForm /> },
		{
			path: '/',
			element: (
				<BaseLayout>
					<ProfilePage />
				</BaseLayout>
			),
		},
		// {
		//     path: "/desktop",
		//     element: (
		//         <BaseLayout>
		//             <DesktopPage />
		//         </BaseLayout>
		//     ),
		// },
		// {
		//     path: "/orders",
		//     element: (
		//         <BaseLayout>
		//             <OrdersPage />
		//         </BaseLayout>
		//     ),
		// },
		{
			path: '/routes',
			element: (
				<BaseLayout>
					<RoutesPage />
				</BaseLayout>
			),
		},
		{
			path: '/map',
			element: (
				<BaseLayout>
					<MapPage />
				</BaseLayout>
			),
		},
		{
			path: '/directory',
			element: (
				<BaseLayout>
					<DirectoryPage />
				</BaseLayout>
			),
		},
		// {
		//     path: "/analytics",
		//     element: (
		//         <BaseLayout>
		//             <AnalyticsPage />
		//         </BaseLayout>
		//     ),
		// },
	]);
	return routes;
}

export default AppRoutes;
