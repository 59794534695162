import axios from 'axios';

// axios.defaults.baseURL = 'http://84.201.161.189:82/api'; // main
axios.defaults.baseURL = 'http://51.250.126.126:82/api'; // new main
// axios.defaults.baseURL = 'http://digital.tab-is.com:83/api'; // bnet24_front_dev
axios.defaults.validateStatus((status) => {
	return status < 500;
});
axios.defaults.headers.common['Authorization'] =
	localStorage.getItem('AccessToken');

export default axios;
