import { Image, Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

// import { AnalyticsIcon } from 'assets/icons/NavIcons/AnalyticsIcon';
// import { BacketIcon } from 'assets/icons/NavIcons/BacketIcon';
// import { DesktopIcon } from 'assets/icons/NavIcons/DesktopIcon';
// import { MapIcon } from 'assets/icons/NavIcons/MapIcon';
import { DirectoryIcon } from 'assets/icons/NavIcons/DirectoryIcon';
import { ProfileIcon } from 'assets/icons/NavIcons/ProfileIcon';
import { RoutesIcon } from 'assets/icons/NavIcons/RoutesIcon';

import './NavBar.scss';

const navItem = [
	{
		path: '/',
		title: 'Профиль',
		icon: (fill) => <ProfileIcon fill={fill} />,
	},
	// {
	// 	path: '/desktop',
	// 	title: 'Рабочий стол',
	// 	icon: (fill) => <DesktopIcon fill={fill} />,
	// },
	// {
	// 	path: '/orders',
	// 	title: 'Заказы',
	// 	icon: (fill) => <BacketIcon fill={fill} />,
	// },
	{
		path: '/routes',
		title: 'Маршруты',
		icon: (fill) => <RoutesIcon fill={fill} />,
	},
	// {
	// 	path: '/map',
	// 	title: 'Карта',
	// 	icon: (fill) => <MapIcon fill={fill} />,
	// },
	{
		path: '/directory',
		title: 'Справочники',
		icon: (fill) => <DirectoryIcon fill={fill} />,
	},
	// {
	// 	path: '/analytics',
	// 	title: 'Аналитика',
	// 	icon: (fill) => <AnalyticsIcon fill={fill} />,
	// },
];

export const NavBar = () => {
	const location = useLocation();
	const user = useSelector((state) => state?.user?.data?.user);

	return (
		<Nav className='current-nav'>
			{navItem.map((el) => {
				const fill = location.pathname === el.path ? '#378FC9' : '#ffffff';
				return (
					<NavLink to={el.path} key={el.path} className='current-nav-item'>
						{el.path === '/' && user?.avatar ? (
							<Image
								src={user?.avatar}
								width={30}
								height={30}
								className='current-nav-item-avatar'
							/>
						) : (
							el.icon(fill)
						)}
						{el.path === '/' && user?.firstName ? (
							<span
								className='current-nav-item-title'
								style={{ color: '#ffffff' }}
							>
								{user.firstName}
							</span>
						) : (
							<span className='current-nav-item-title' style={{ color: fill }}>
								{el.title}
							</span>
						)}
					</NavLink>
				);
			})}
		</Nav>
	);
};
