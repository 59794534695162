import { axios } from 'core';
import { setTransport } from 'store/slices/transportSlice';
// import { setTransport } from "store/slices/transportSlice"

const addColor = () => {
	return (
		'#' +
		Math.floor(Math.random() * 16777215)
			.toString(16)
			.padStart(6, '0')
			.toUpperCase()
	);
};

const createTraceColor = (arr) => {
	let res = JSON.parse(JSON.stringify(arr));
	// res?.map((el) => (el.traceColor = addColor()));
	// res?.map((el) => (el.traceColor = ''));
	res?.map((el) =>
		el.traceColor === '' ? (el.traceColor = '#000000') : el.traceColor
	);
	return res;
};

export const getAllTransports = () => {
	let access_token = localStorage.getItem('AccessToken');
	return async (dispatch) => {
		try {
			const res = await axios.get(
				`/logistics/transport/all`,

				{
					headers: {
						Authorization: `${access_token}`,
					},
				}
			);

			dispatch(setTransport(createTraceColor(res.data.transports)));
			// dispatch(setDriver(updateDrivers(res.data.drivers, res.data.transports)))
		} catch (error) {
			console.log('error', error.message);
		}
	};
};

export const updateTransport = (data) => {
	let access_token = localStorage.getItem('AccessToken');
	return async (dispatch) => {
		try {
			const res = await axios.put(`/logistics/transport/update`, data, {
				headers: {
					Authorization: `${access_token}`,
				},
			});
			if (res.status === 200) {
				dispatch(getAllTransports());
			}
		} catch (error) {
			console.log('error', error.message);
		}
	};
};
