import { axios } from 'core';
import { setOrder } from 'store/slices/orderSlice';
import { removeUser } from 'store/slices/userSlice';

const allOrderPoints = (arr) => {
	let allPoints = [];
	// console.log("orders", orders)
	for (let it in arr) {
		arr[it].orders.map((el) => allPoints.push(el));
	}
	return allPoints;
};

export const getOrdersList = (start, end) => {
	// console.log('start, end', start, end);
	let access_token = localStorage.getItem('AccessToken');
	const data = {
		startDate: start ? start : '',
		endDate: end ? end : '',
		status: 'incoming',
		// startPage: 0,
		// endpage: 100,
		// withProd: true,
	};

	return async (dispatch) => {
		try {
			const res = await axios.get(
				`/logistics/orders/list?startDate=${data.startDate}&endDate=${data.endDate}&status=${data.status}&startPage=${data.startPage}&endPage=${data.endpage}&withProd=${data.withProd}`,

				{
					headers: {
						Authorization: `${access_token}`,
					},
				}
			);

			// console.log('res getOrdersList', res);

			dispatch(setOrder(allOrderPoints(res.data.customers)));
		} catch (error) {
			console.log('error', error);
			if (error.response.data.message === 'invalid Access token') {
				dispatch(removeUser());
			}
		}
	};
};
