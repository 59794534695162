
export const TruckIcon = (props) => {
    return (
        <svg
            width="32"
            height="27"
            viewBox="0 0 17 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
                border:  props.currentTransportStatus === 'truck' || props.valueType === 1 ? `1px solid ${props.color} ` : '1px solid #ededed',
                padding: '5px',
                borderRadius: '8px',
                boxShadow: props.currentTransportStatus === 'truck' || props.valueType === 1 ? 'none' : '1px 2px #d8d8d8',
                backgroundColor: props.currentTransportStatus === 'truck' ? '#379dd9' : 'transparent',
            }}
        >
            <path
                d="M14.8125 8.5H14.25V5.96875C14.25 5.59375 14.0859 5.19531 13.8047 4.91406L11.8359 2.94531C11.5547 2.66406 11.1797 2.5 10.7812 2.5H9.75V1.35156C9.75 0.742188 9.21094 0.25 8.57812 0.25H1.14844C0.515625 0.25 0 0.742188 0 1.35156V8.17188C0 8.78125 0.515625 9.25 1.14844 9.25H1.52344C1.5 9.39062 1.5 9.50781 1.5 9.625C1.5 10.6797 2.32031 11.5 3.375 11.5C4.40625 11.5 5.25 10.6797 5.25 9.625C5.25 9.50781 5.22656 9.39062 5.20312 9.25H9.77344C9.75 9.39062 9.75 9.50781 9.75 9.625C9.75 10.6797 10.5703 11.5 11.625 11.5C12.6562 11.5 13.5 10.6797 13.5 9.625C13.5 9.50781 13.4766 9.39062 13.4531 9.25H14.8125C14.9062 9.25 15 9.17969 15 9.0625V8.6875C15 8.59375 14.9062 8.5 14.8125 8.5ZM10.7812 3.25C10.9688 3.25 11.1562 3.34375 11.2969 3.48438L13.2656 5.45312C13.2891 5.47656 13.2891 5.5 13.3125 5.52344H9.75V3.27344H10.7812V3.25ZM3.375 10.75C2.74219 10.75 2.25 10.2578 2.25 9.625C2.25 9.01562 2.74219 8.5 3.375 8.5C3.98438 8.5 4.5 9.01562 4.5 9.625C4.5 10.2578 3.98438 10.75 3.375 10.75ZM4.85156 8.5C4.52344 8.05469 3.98438 7.75 3.375 7.75C2.76562 7.75 2.22656 8.05469 1.875 8.5H1.14844C0.914062 8.5 0.75 8.35938 0.75 8.17188V1.35156C0.75 1.16406 0.914062 1 1.14844 1H8.57812C8.8125 1 9 1.16406 9 1.35156V8.5H4.85156ZM11.625 10.75C10.9922 10.75 10.5 10.2578 10.5 9.625C10.5 9.01562 10.9922 8.5 11.625 8.5C12.2344 8.5 12.75 9.01562 12.75 9.625C12.75 10.2578 12.2344 10.75 11.625 10.75ZM11.625 7.75C10.9922 7.75 10.4766 8.05469 10.125 8.5H9.75V6.25H13.5V8.5H13.1016C12.7734 8.05469 12.2344 7.75 11.625 7.75Z"
                fill={props.currentTransportStatus === 'truck' || props.color ? props.fill : 'black'}/>
        </svg>
    );
}