import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    data: {
        transports: [],
        drivers: [],
    },
}

const transportSlice = createSlice({
    name: "transport",
    initialState,
    reducers: {
        setTransport(state, action) {
            state.data.transports = action.payload
        },
        setDriver(state, action) {
            state.data.drivers = action.payload
        },
    },
})

export const { setTransport, setDriver } = transportSlice.actions

export default transportSlice.reducer
