import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    data: {
        customers:[]
    },
}

const customerSlice = createSlice({
    name: "customer",
    initialState,
    reducers: {
        setCustomer(state, action) {
            state.data.customers = action.payload
        },
    },
})

export const { setCustomer } = customerSlice.actions

export default customerSlice.reducer
