import { AccordionBox, MapBox, PageTitle, SwitchBox } from 'components';
import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setSessions } from 'store/slices/deliverySessionsSlice';

import { OpenMapIcon } from 'assets/icons/AppIcons/OpenMapIcon';
import { DateFilterBox } from 'components/DateFilterBox/DateFilterBox';
import { PageView } from 'components/PageView/PageView';
import { RightModalMap } from 'components/RightModalMap/RightModalMap';
import { RightModalOrder } from 'components/RightModalOrder/RightModalOrder';
import { setOrder } from 'store/slices/orderSlice';
import { setTransport } from 'store/slices/transportSlice';
import { setActiveZone } from 'store/slices/zoneSlice';
import { getOrdersList } from 'utils/api/order';
import {
	createDeliverySessions,
	getDeliverySessionsByDate,
} from 'utils/api/sessions';
import { getAllTransports } from 'utils/api/transport';
import { getAllDeliveryZones } from 'utils/api/zone';
import { RightModalTransports } from './../../components/RightModalTransports/RightModalTransports';
import './RoutesPage.scss';

const RoutesPage = () => {
	const dispatch = useDispatch();
	const orders = useSelector((state) => state?.order?.data);
	const sessions = useSelector((state) => state?.sessions?.data);
	const allTransportsSessions = useSelector(
		(state) => state.sessions.allTransports
	);
	const allTransports = useSelector((state) => state.transport.data.transports);
	const allZones = useSelector((state) => state.zone.data);
	const activeZone = useSelector((state) => state.zone.activeZone);

	const [idTransport, setIdTransport] = useState('');
	const [indexSession, setIndexSession] = useState();
	const [allPoints, setAllPoints] = useState([]);
	const [showMapModal, setShowMapModal] = useState(false);
	const [showTransportsModal, setShowTransportsModal] = useState(false);
	const [showOrderModal, setShowOrderModal] = useState(false);
	const [findPoint, setFindPoint] = useState(null);
	const [orderModal, setOrderModal] = useState(null);

	let updateSessions = JSON.parse(JSON.stringify(sessions));
	let updateOrders = JSON.parse(JSON.stringify(orders));
	let updateAllTransportsSessions = JSON.parse(
		JSON.stringify(allTransportsSessions)
	);
	let updateAllTransports = JSON.parse(JSON.stringify(allTransports));

	useEffect(() => {
		dispatch(getOrdersList());
		dispatch(getAllTransports());
		dispatch(getDeliverySessionsByDate());
		dispatch(getAllDeliveryZones());
	}, []);

	// console.log('RoutePage allZones:', allZones);

	useEffect(() => {
		statusOrdersCounter();
	}, [orders]);

	useEffect(() => {
		getAllPoints();
	}, [orders, sessions, allTransports, allTransportsSessions]);

	const changedColor = () => {
		updateAllTransports.forEach((element) => {
			updateSessions.forEach((it) => {
				if (it.transport.Id === element.Id) {
					element.traceColor = it.transport.traceColor;
				}
			});
		});
		dispatch(setTransport(updateAllTransports));
	};

	// получение ай-ди выбранного транспорта
	const handlerTransport = (id, index) => {
		setIdTransport(id);
		setIndexSession(index);
	};

	const handlerEmptyTransport = (car, indexSession) => {
		setIdTransport(car.Id);
		// setIdxSession(indexSession);

		let res = [];
		updateAllTransportsSessions?.map((el) => {
			if (el.transport.Id === car.Id) {
				el.transport.traceColor = car.traceColor;
				el.Id = '';
				el.orders.length = 0;
				el.deliveryZone.Id = activeZone;
				el.transport.zoneId = activeZone;
				// el.counterSession = res.length;
				res.unshift({
					...el,
					indexSession: updateSessions.length,
					counterSession: res.length,
				});
			}
		});

		updateSessions.push(res[0]);
		dispatch(setSessions(updateSessions));
	};

	// формирование объекта с новыми сессиями для отправки на сервер
	const createSession = () => {
		let res = [];
		dispatch(setActiveZone(''));
		updateSessions?.map((session) => {
			res.push({
				Id: session.Id,
				transportId: session.transport.Id,
				traceColor: session.transport.traceColor,
				deliveryZoneId: session.deliveryZone.Id,
				driverId: session.driver.Id,
				startDate: getTomorow(),
				orderPoints: findOrders(session.orders),
			});
		});

		dispatch(createDeliverySessions(res));
	};

	// счетчик заказов
	function statusOrdersCounter() {
		let statuses = [
			{
				status: 'Incoming',
				text: 'входящие',
				counter: orders?.length,
			},
			{
				status: 2,
				text: 'активные',
				counter: allPoints?.length,
			},
			{
				status: 'Shipped',
				text: 'завершенные',
				counter: 0,
			},
		];
		return statuses;
	}

	// формирование данных о заказах транспорта для добавления в объект сессий
	const findOrders = (orders) => {
		let res = [];
		orders?.map((el) => {
			res.push({
				position: el.position,
				orderId: el.Id,
				deliveryPointId: el.deliveryPoint.Id,
			});
		});
		return res;
	};

	// функция получения следующей даты
	const correctData = (str) => {
		return str.length < 2 ? '0' + str : str;
	};

	const getTomorow = () => {
		let today = new Date();

		let tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);

		return (
			tomorrow.getFullYear() +
			'-' +
			correctData((tomorrow.getMonth() + 1).toString()) +
			'-' +
			correctData(tomorrow.getDate().toString()) +
			' ' +
			new Intl.DateTimeFormat('ru', {
				hour: 'numeric',
				minute: 'numeric',
				second: 'numeric',
			}).format(tomorrow)
		);
	};

	// поиск сессий с одинаковыми координатами
	const findDuplicateSessionsCoordinates = (arr, point) => {
		return arr.filter(
			(el) =>
				el.deliveryPoint.geoPosition.latitude !==
					point.deliveryPoint.geoPosition.latitude &&
				el.deliveryPoint.geoPosition.longitude !==
					point.deliveryPoint.geoPosition.longitude
		);
	};

	// поиск заказов с одинаковыми координатами
	const findDuplicateOrdersCoordinates = (prev, point, arr) => {
		let res = prev;
		arr.map((el) => {
			if (
				el.deliveryPoint.geoPosition.latitude ===
					point.deliveryPoint.geoPosition.latitude &&
				el.deliveryPoint.geoPosition.longitude ===
					point.deliveryPoint.geoPosition.longitude
			) {
				res.push({ ...el, position: res.length + 1 });
			}
		});
		return res;
	};

	// добавление заказа выбранному транспорту
	const addOrderTransport = (point) => {
		updateSessions.map((el) => {
			if (el.transport.Id === idTransport && el.indexSession === indexSession) {
				el.orders = findDuplicateOrdersCoordinates(
					el.orders,
					point,
					updateOrders
				);
			}
		});
		updateOrders = findDuplicateSessionsCoordinates(updateOrders, point);
		dispatch(setSessions(updateSessions));
		dispatch(setOrder(updateOrders));
	};

	const addOneOrderTransport = (point) => {
		// console.log('point addOneOrderTransport: ', point);
		point = JSON.parse(JSON.stringify(point));
		updateSessions.map((el) => {
			// console.log('el', el);
			if (el.transport.Id === idTransport && el.indexSession === indexSession) {
				// point.deliveryPoint.geoPosition.latitude =
				// 	point.deliveryPoint.geoPosition.latitude + Math.random() / 100000;
				// point.deliveryPoint.geoPosition.longitude =
				// 	point.deliveryPoint.geoPosition.longitude + Math.random() / 100000;
				el.orders.push({ ...point, position: el.orders.length + 1 });
			}
		});
		updateOrders = updateOrders.filter((el) => el.Id !== point.Id);
		dispatch(setSessions(updateSessions));
		dispatch(setOrder(updateOrders));
	};

	// отмена добавления заказа в транспорт
	const removeOrderTransport = (point) => {
		// console.log('point', point);
		point = JSON.parse(JSON.stringify(point));
		updateSessions.map((el) => {
			if (
				el.transport.Id === point.transport.Id &&
				el.indexSession === indexSession
			) {
				// console.log('point', point);
				// point.order.deliveryPoint.geoPosition.latitude =
				// 	point.order.deliveryPoint.geoPosition.latitude -
				// 	Math.random() / 100000;
				// point.order.deliveryPoint.geoPosition.longitude =
				// 	point.order.deliveryPoint.geoPosition.longitude -
				// 	Math.random() / 100000;
				el.orders = el.orders.filter((it) => it.Id !== point.order.Id);
				// el.orders = findDuplicateSessionsCoordinates(el.orders, point.order);
			}
			return updateSessions;
		});
		dispatch(setSessions(updateSessions));
		// updateOrders = findDuplicateOrdersCoordinates(
		// 	updateOrders,
		// 	point.order,
		// 	res
		// );
		// console.log('updateSessions', updateSessions);
		updateOrders.push(point.order);
		dispatch(setOrder(updateOrders));
	};

	const getAllPoints = () => {
		let res = [];
		for (let session in sessions) {
			let transport = sessions[session].transport;
			let driver = sessions[session].driver;
			let indexSession = sessions[session].indexSession;
			if (sessions[session].orders) {
				sessions[session].orders.map((order) => {
					res.push({ order, transport, driver, indexSession });
				});
			}
		}
		setAllPoints(res);
	};

	const visibleMapModal = (e) => {
		// e.stopPropagation();
		setShowMapModal(true);
	};

	const hideMapModal = () => setShowMapModal(false);

	const visibleModalTransports = () => {
		changedColor();

		setShowTransportsModal(true);
	};

	const hideTransportsModal = () => setShowTransportsModal(false);

	const visibleModalOrder = (el) => {
		setOrderModal(el);
		setShowOrderModal(true);
	};

	const hideOrderModal = () => setShowOrderModal(false);

	const handlerVisiblePoint = (point) => {
		setFindPoint(point);
	};

	// Удаление транспорта из сессии
	const removeSessionInSessions = (arr, indexSession) => {
		let res = arr.filter((el) => {
			return el.indexSession !== indexSession;
		});
		dispatch(setSessions(res));
		setIdTransport('');
	};

	// Добавление заказов в список из удаленного транспорта
	const addOrdersFromSession = (arr, session) => {
		arr.map((el) => {
			if (
				el.transport.Id === session.transport.Id &&
				el.indexSession === session.indexSession
			) {
				dispatch(setOrder(updateOrders.concat(session.orders)));
				removeSessionInSessions(arr, session.indexSession);
			}
		});
	};

	const handlerDelTransport = (e, session) => {
		e.stopPropagation();
		let res = window.confirm('Сбросить маршрут для этого транспорта?');
		if (res === true) {
			addOrdersFromSession(updateSessions, session);
		} else {
			return;
		}
	};

	return (
		<PageView>
			<RightModalOrder
				visible={showOrderModal}
				onHide={hideOrderModal}
				titleCloseButton='Заказ'
				orderModal={orderModal}
			/>
			<RightModalTransports
				visible={showTransportsModal}
				onHide={hideTransportsModal}
				titleCloseButton='Транспорт'
				allTransports={allTransports}
				onClick={handlerEmptyTransport}
				sessions={sessions}
			/>
			<RightModalMap
				visible={showMapModal}
				onHide={hideMapModal}
				titleCloseButton='Карта'
				dataModal={''}
				idTransport={idTransport}
				indexSession={indexSession}
				sessions={sessions}
				orders={orders}
				addOrderTransport={addOrderTransport}
				removeOrderTransport={removeOrderTransport}
				allPoints={allPoints}
			/>
			<PageTitle title='Маршруты' />
			<div className='action-route-wrapper'>
				<SwitchBox
					badge={true}
					active='Incoming'
					status={statusOrdersCounter()}
				/>
				<DateFilterBox />
			</div>
			<div className='routes'>
				<AccordionBox
					onClick={handlerTransport}
					sessions={sessions}
					allZones={allZones}
					createSession={createSession}
					visibleModalTransports={visibleModalTransports}
					visiblePoint={handlerVisiblePoint}
					delTransport={handlerDelTransport}
					visibleOrder={visibleModalOrder}
				/>
				<Card className='routes-data-map mt-3'>
					<MapBox
						idTransport={idTransport}
						sessions={sessions}
						orders={orders}
						addOrderTransport={addOrderTransport}
						addOneOrderTransport={addOneOrderTransport}
						removeOrderTransport={removeOrderTransport}
						allPoints={allPoints}
						findPoint={findPoint}
					/>
					<OpenMapIcon
						className='routes-data-map-icon'
						onClick={visibleMapModal}
					/>
				</Card>
			</div>
		</PageView>
	);
};

export default RoutesPage;
