import { Map, Placemark, YMaps } from '@pbe/react-yandex-maps';

import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import './MapBox.scss';

const Portal = ({ children, getHTMLElementId }) => {
	const mount = document.getElementById(getHTMLElementId);
	const el = document.createElement('div');

	useEffect(() => {
		if (mount) mount.appendChild(el);

		return () => {
			if (mount) mount.removeChild(el);
		};
	}, [el, mount]);

	if (!mount) return null;
	return createPortal(children, el);
};

export const MapBox = (props) => {
	const [activePortal, setActivePortal] = useState(false);
	const [mapZoom, setMapZoom] = useState(7);
	const [mapState, setMapState] = useState({
		center: [44.9572, 35.1108],
		zoom: 7,
	});
	const [pointOrder, setPointOrder] = useState({});

	const defaultState = {
		center: [44.9572, 35.1108],
		zoom: 7,
	};

	useEffect(() => {
		if (props.findPoint?.deliveryPoint) {
			findPoint();
		}
	}, [props.findPoint]);

	const dateFormatter = (date) => {
		let res = new Date(date);

		let day =
			res.getDate() < 10
				? '0' + res.getDate().toString()
				: res.getDate().toString();
		let month =
			res.getMonth() + 1 < 10
				? '0' + (res.getMonth() + 1).toString()
				: (res.getMonth() + 1).toString();

		let year = res.getFullYear().toString();
		// setFormatDate(day + '.' + month + '.' + year);
		return day + '.' + month + '.' + year;
	};

	const findPoint = () => {
		let lat = props.findPoint?.deliveryPoint?.geoPosition.latitude;
		let lon = props.findPoint?.deliveryPoint?.geoPosition.longitude;

		setMapState({
			center: [lat, lon],
			zoom: 10,
		});
	};

	const handlerClickOrder = (point) => {
		setPointOrder(point);
		props.idTransport
			? props.addOneOrderTransport(point)
			: alert('Транспорт не выбран');
	};

	// добавить статус доставки
	// <span>Статус доставки: ${point.deliveryStatus.status} довоз</span>

	const HintContent = (point) => {
		return `<div class="hint-content"><span>Дата: ${dateFormatter(
			point.orderCreated
		)}</span>
				 <span>Статус доставки: ${
						point.deliveryStatus.status === 'additional' ? 'Довоз' : ''
					}</span>
				 <span>Заказ №${point.textOrderNumber},</span>
		     <span> ${point.deliveryPoint.address},</span>
		     <span>${point.deliveryPoint.companyName}</span>
		     <span>Сумма заказа: ${point.totalSum} p.</span>
		     <span>Вес заказа: ${point.totalWeight}</span>

		</div>`;
	};

	const PresetIconTransport = (arr, point) => {
		let res = arr.filter(
			(el) =>
				el.order.deliveryPoint.geoPosition.latitude ===
					point.deliveryPoint.geoPosition.latitude &&
				el.order.deliveryPoint.geoPosition.longitude ===
					point.deliveryPoint.geoPosition.longitude
		);
		return res.length > 1
			? 'islands#blackCircleIcon'
			: 'islands#invertedBlueClusterIcons';
	};

	const PresetIcon = (arr, point) => {
		let res = arr.filter(
			(el) =>
				el.deliveryPoint.geoPosition.latitude ===
					point.deliveryPoint.geoPosition.latitude &&
				el.deliveryPoint.geoPosition.longitude ===
					point.deliveryPoint.geoPosition.longitude
		);
		return res.length > 1
			? 'islands#blackCircleIcon'
			: 'islands#invertedBlueClusterIcons';
	};

	// маркеры довозных заказов

	const PresetAdditionalIcon = (arr, point) => {
		let res = arr.filter(
			(el) =>
				el.deliveryPoint.geoPosition.latitude ===
					point.deliveryPoint.geoPosition.latitude &&
				el.deliveryPoint.geoPosition.longitude ===
					point.deliveryPoint.geoPosition.longitude &&
				el.deliveryStatus.status === point.deliveryStatus.status
		);
		return res.length > 1 ? 'islands#circleDotIcon' : 'islands#blackDotIcon';
	};

	const IconContent = (point) => {
		let res = props.orders?.filter(
			(el) =>
				el.deliveryPoint.geoPosition.latitude ===
					point.deliveryPoint.geoPosition.latitude &&
				el.deliveryPoint.geoPosition.longitude ===
					point.deliveryPoint.geoPosition.longitude
		);
		return `<div><span>${res.length > 1 ? res.length : ''}</span></div>`;
	};

	// const PointOrderPosition = (point) => {
	// 	let position = [];
	// 	let res = props.allPoints?.filter(
	// 		(el) =>
	// 			el.order.deliveryPoint.geoPosition?.latitude ===
	// 				point.order.deliveryPoint.geoPosition?.latitude &&
	// 			el.order.deliveryPoint.geoPosition?.longitude ===
	// 				point.order.deliveryPoint.geoPosition?.longitude
	// 	);
	// 	res.map((el) => {
	// 		position.push(el.order.position);
	// 	});
	// 	// console.log('position', position);
	// 	if (position.length === 1) return `<div><span>${position[0]}</span></div>`;
	// 	if (position[0] === position[position.length - 1])
	// 		return `<div><span>${position[0] - (position.length - 1)} - ${
	// 			position[position.length - 1]
	// 		}</span></div>`;
	// 	return `<div><span>${position[0]} - ${
	// 		position[position.length - 1]
	// 	}</span></div>`;
	// };

	const findOrdersContent = (arr, point) => {
		let res = arr.map((el) => {
			return `<div class='hint-order'><span>Дата: ${dateFormatter(
				el.order.orderCreated
			)}</span>

					 <span>Заказ №${el.order.textOrderNumber}</span>
			     <span> ${el.order.deliveryPoint.address}</span>
			     <span>${el.order.deliveryPoint.companyName}</span>
			     <span>Сумма заказа: ${el.order.totalSum} p.</span>
			     <span>Вес заказа: ${el.order.totalWeight}</span>
					 </div>`;
		});
		return res.join('');
	};

	const HintContentOrder = (point) => {
		console.log('hintContent', point);
		let res = props.allPoints?.filter(
			(el) =>
				el.order.deliveryPoint.geoPosition.latitude ===
					point.deliveryPoint.geoPosition.latitude &&
				el.order.deliveryPoint.geoPosition.longitude ===
					point.deliveryPoint.geoPosition.longitude
		);
		console.log('hintContent', res);
		return `<div class="hint-content">${findOrdersContent(res, point)}</div>`;
		// return `<div class="hint-content">${findOrdersContent(res, point)}</div>`;
	};

	const GeometryIcon = (point) => {
		let res = props.orders.filter(
			(el) =>
				el.deliveryPoint.geoPosition.latitude ===
					point.deliveryPoint.geoPosition.latitude &&
				el.deliveryPoint.geoPosition.longitude ===
					point.deliveryPoint.geoPosition.longitude
		);

		if (res.length > 1) {
			return [
				point.deliveryPoint.geoPosition.latitude + Math.random() / 10000,
				point.deliveryPoint.geoPosition.longitude + Math.random() / 10000,
			];
		}
		return [
			point.deliveryPoint.geoPosition.latitude,
			point.deliveryPoint.geoPosition.longitude,
		];
	};

	const GeometryIconTransport = (point) => {
		let res = props.orders?.filter(
			(el) =>
				el.deliveryPoint.geoPosition.latitude ===
					point.deliveryPoint.geoPosition.latitude &&
				el.deliveryPoint.geoPosition.longitude ===
					point.deliveryPoint.geoPosition.longitude
		);

		if (res?.length > 1) {
			return [
				point.deliveryPoint.geoPosition.latitude + Math.random() / 10000,
				point.deliveryPoint.geoPosition.longitude + Math.random() / 10000,
			];
		}
		return [
			point.deliveryPoint.geoPosition.latitude + Math.random() / 10000,
			point.deliveryPoint.geoPosition.longitude + Math.random() / 10000,
		];
	};

	const PortalContent = () => {
		if (pointOrder.order) {
			return (
				<div className='portal-content'>
					<span>Заказ №{pointOrder?.order.textOrderNumber},</span>
					<span>{pointOrder?.order.deliveryPoint.address},</span>
					<span>{pointOrder?.order.deliveryPoint.companyName}</span>
					<span>Сумма заказа: {pointOrder?.order.totalSum} p.</span>
					<span>Вес заказа: {pointOrder?.order.totalWeight}</span>
					<div
						style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}
					>
						<Button
							className='action__add-btn'
							onClick={
								props.idTransport
									? () => {
											console.log('point', pointOrder);
											props.removeOrderTransport(pointOrder);
									  }
									: () => alert('Транспорт не выбран')
							}
						>
							Из машины
						</Button>
					</div>
				</div>
			);
		}
		return (
			<div className='portal-content'>
				<span>Заказ №{pointOrder?.textOrderNumber},</span>
				<span>{pointOrder?.deliveryPoint.address},</span>
				<span>{pointOrder?.deliveryPoint.companyName}</span>
				<span>Сумма заказа: {pointOrder?.totalSum} p.</span>
				<span>Вес заказа: {pointOrder?.totalWeight}</span>
				<div
					style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}
				>
					<Button
						className='action__add-btn'
						onClick={
							props.idTransport
								? () => {
										console.log('point', pointOrder);
										props.addOneOrderTransport(pointOrder);
								  }
								: () => alert('Транспорт не выбран')
						}
					>
						Добавить в транспорт
					</Button>
				</div>
			</div>
		);
	};

	const openPortal = (point) => {
		setPointOrder(point);
		setActivePortal(false);
		setTimeout(() => {
			setActivePortal(true);
		}, 0);
	};

	// console.log('props.allPoints', props.allPoints);

	return (
		<YMaps sversion={'2.1.79'}>
			<div className={`map-box ${props.className}`}>
				<Map
					defaultState={defaultState}
					state={mapState}
					className='map-box-map'
					style={{ width: '100%' }}
					onBoundsChange={(e) => {
						if (e.get('newZoom') !== e.get('oldZoom')) {
							// Зум изменился
							setMapZoom(e.get('newZoom'));
							// console.log('mapState.zoom', e.get('newZoom'));
						}
					}}
				>
					{props.allPoints?.map((point, index) => {
						// выбранные маркеры

						return (
							<Placemark
								key={point.order.Id + index}
								// geometry={[
								// 	point.order?.deliveryPoint?.geoPosition?.latitude,
								// 	point.order?.deliveryPoint?.geoPosition?.longitude,
								// ]}
								geometry={GeometryIconTransport(point.order)}
								options={{
									preset: PresetIconTransport(props.allPoints, point.order),
									// preset: 'islands#blackStretchyIcon',
									iconColor: point.transport?.traceColor,
								}}
								properties={{
									iconContent: point.order.position,
									// iconContent: PointOrderPosition(point),
									// hintContent: HintContentOrder(point),
									balloonContent:
										'<div id="driver-2" class="driver-card"></div>',
								}}
								// onClick={() => props.removeOrderTransport(point)}
								onClick={() => openPortal(point)}
								modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
							/>
						);
					})}
					{props.orders?.map((point, index) => {
						// не выбранные маркеры

						if (point?.deliveryStatus?.status === 'additional') {
							// довозные заказы
							let additional = true;
							return (
								<Placemark
									key={point.Id + index}
									geometry={GeometryIcon(point)}
									options={{
										preset:
											mapZoom < 18
												? PresetAdditionalIcon(props.orders, point)
												: 'islands#redDotIcon',
										iconColor: 'rgba(0,0,0, 1)',
									}}
									properties={{
										iconContent:
											mapZoom < 18 ? IconContent(point, additional) : '',
										hintContent: HintContent(point),
										// balloonContent:
										// 	'<div id="driver-2" class="driver-card"></div>',
									}}
									// onClick={() => openPortal(point)}
									onClick={() => handlerClickOrder(point)}
									modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
								/>
							);
						} else {
							return (
								<Placemark
									key={point.Id + index}
									geometry={GeometryIcon(point)}
									options={{
										preset:
											mapZoom < 18
												? PresetIcon(props.orders, point)
												: 'islands#invertedBlueClusterIcons',
										iconColor: 'rgba(0,0,0, 1)',
									}}
									properties={{
										iconContent: mapZoom < 18 ? IconContent(point) : '',
										hintContent: HintContent(point),
										// balloonContent:
										// 	'<div id="driver-2" class="driver-card"></div>',
									}}
									// onClick={() => openPortal(point)}
									onClick={() => handlerClickOrder(point)}
									modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
								/>
							);
						}
					})}
					{props.point && (
						<Placemark
							geometry={[
								props.point.deliveryPoint?.geoPosition?.latitude,
								props.point.deliveryPoint?.geoPosition?.longitude,
							]}
							options={{
								preset: 'islands#invertedBlueClusterIcons',
								iconColor: 'rgba(0,0,0, 1)',
							}}
							properties={{
								hintContent: HintContent(props.point),
								// iconContent: IconContent(props.point),
								// balloonContent: '<div id="driver-2" class="driver-card"></div>',
							}}
							modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
						/>
					)}
				</Map>
				{activePortal && pointOrder && (
					<Portal getHTMLElementId={'driver-2'}>
						<PortalContent point={pointOrder} />
						{/* <Button title='Button Portal' /> */}
					</Portal>
				)}
			</div>
		</YMaps>
	);
};
