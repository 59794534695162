import { useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { CloseButton } from '../CloseButton/CloseButton';
import { FormInput } from '../FormInput/FormInput';
import './RightModalTransport.scss';

import ColorPicker from 'react-pick-color';

import { updateTransport } from '../../utils/api/transport';
import { FormSelect } from '../FormSelect/FormSelect';
import { SwitchTransportModalBox } from '../SwitchTransportModalBox/SwitchTransportModalBox';

export const RightModalTransport = (props) => {
	const dispatch = useDispatch();
	const [transportModel, setTransportModel] = useState('');
	const [licenseId, setLicenseId] = useState('');
	const [loadCapacity, setLoadCapacity] = useState('');
	const [bulkSpace, setBulkSpace] = useState('');
	const [consumption, setConsumption] = useState('');
	const [fuelType, setFuelType] = useState('');
	const [currentTransportStatus, setCurrentTransportStatus] = useState('all');
	const [driver, setDriver] = useState({});
	const [hired, setHired] = useState(false);
	const [currentTransportType, setCurrentTransportType] = useState(
		props.dataTransport?.transportType
	);

	const [visiblePicker, setVisiblePicker] = useState(false);

	const [color, setColor] = useState('');

	useEffect(() => {
		setVisiblePicker(false);
		setTransportModel(props.dataTransport?.transportModel);
		setLicenseId(props.dataTransport?.licenseId);
		setLoadCapacity(props.dataTransport?.loadCapacity);
		setBulkSpace(props.dataTransport?.bulkSpace);
		setConsumption(props.dataTransport?.consumption);
		setFuelType(props.dataTransport?.fuelType);
		setDriver(props.dataTransport?.driver);
		setHired(props.dataTransport?.hired);
		setColor(props.dataTransport?.traceColor);
	}, [props]);

	const handlerTransportModel = (e) => setTransportModel(e.target.value);
	const handlerLicenseId = (e) => setLicenseId(e.target.value);
	const handlerLoadCapacity = (e) => setLoadCapacity(e.target.value);
	const handlerBulkSpace = (e) => setBulkSpace(e.target.value);
	const handlerConsumption = (e) => setConsumption(e.target.value);
	const handlerFuelType = (e) => setFuelType(e.target.value);
	const handlerHired = () => setHired((prevState) => !prevState);
	const handlerDriver = (e) => setDriver(e.target.value);
	const handlerCheckDriver = (val) => setDriver(val);

	const handlerVisiblePicker = () => setVisiblePicker(!visiblePicker);

	const assignDriverToTransport = () => {
		dispatch(
			updateTransport({
				Id: props.dataTransport.Id,
				transportModel,
				licenseId,
				bulkSpace: Number(parseFloat(bulkSpace)),
				consumption: Number(consumption),
				fuelType: Number(fuelType),
				loadCapacity: Number(loadCapacity),
				hired,
				driverId: driver.Id,
				transportType: currentTransportType,
				traceColor: color,
			})
		);

		props.onHideTransportModal();
	};

	return (
		<div className={props.visibleModalTransport ? 'right-modal' : 'hide-modal'}>
			{/*<div>*/}
			<CloseButton
				onClick={() => props.onHideTransportModal()}
				className='close-btn'
				titleCloseButton={props.titleCloseButton}
			/>
			<div className='right-modal-content right-modal-content-transports'>
				<Card
					style={{
						width: '100%',
						height: 'fit-content',
						padding: 35,
					}}
				>
					<div className='right-modal-content-switch-transport'>
						<SwitchTransportModalBox
							transportType={props.dataTransport?.transportType}
							color={props.dataTransport?.traceColor}
							onChangeTransportType={(type) => setCurrentTransportType(type)}
						/>
					</div>
					<div className='right-modal-content-checkbox-transport'>
						<input
							className='right-modal-content-input'
							type='checkbox'
							checked={hired}
							onChange={handlerHired}
						/>
						<label className='right-modal-content-label'>
							Наемный транспорт
						</label>
					</div>
					{visiblePicker && (
						<div style={{ position: 'absolute', zIndex: 999, display: 'flex' }}>
							<ColorPicker
								color={color}
								onChange={(color) => setColor(color.hex.toUpperCase())}
							/>
							<CloseButton
								onClick={handlerVisiblePicker}
								fill='black'
								style={{ marginLeft: 10 }}
							/>
						</div>
					)}
					<Form className='d-flex flex-column mb-5'>
						<FormInput
							style={{
								backgroundColor: color,
								width: 30,
								height: 30,
								borderRadius: 30,
							}}
							className='right-modal-content-transports-input'
							label='Цвет'
							type='button'
							onClick={handlerVisiblePicker}
						/>
						<FormInput
							className='right-modal-content-transports-input'
							label='Марка'
							placeholder='Например, Мерседес-Бенц 410D'
							type='text'
							value={transportModel}
							onChange={handlerTransportModel}
						/>
						<FormInput
							className='right-modal-content-transports-input'
							label='Гос. номер'
							placeholder='Например, E627AX193'
							type='text'
							value={licenseId}
							onChange={handlerLicenseId}
						/>
						<FormInput
							className='right-modal-content-transports-input'
							label='Грузоподъемность, кг'
							placeholder='Например, 3500'
							type='text'
							value={loadCapacity}
							onChange={handlerLoadCapacity}
						/>
						<FormInput
							className='right-modal-content-transports-input'
							label='Объем, м³'
							placeholder='Например, 2000'
							type='text'
							value={bulkSpace}
							onChange={handlerBulkSpace}
						/>
						<FormInput
							className='right-modal-content-transports-input'
							label='Расход на 100 км, л'
							placeholder='Например, 10'
							type='text'
							value={consumption}
							onChange={handlerConsumption}
						/>
						<FormInput
							className='right-modal-content-transports-input'
							label='Вид топлива'
							placeholder='Например, АИ-92'
							type='text'
							value={fuelType}
							onChange={handlerFuelType}
						/>
						<FormSelect
							className='right-modal-content-transports-select'
							label='Водитель/Экспедитор'
							placeholder='Выберите транспорт'
							type='text'
							allDrivers={props.allDrivers}
							value={driver?.driverName}
							onChange={handlerDriver}
							handlerCheckDriver={handlerCheckDriver}
						/>
					</Form>
				</Card>
				<div className='action'>
					<Button className='action__add-btn' onClick={assignDriverToTransport}>
						Сохранить
					</Button>
					<Button className='action__delete-btn'>Отмена</Button>
				</div>
			</div>
		</div>
	);
};
