import { CloseIcon } from 'assets/icons/AppIcons/CloseIcon';

import './CloseButton.scss';

export const CloseButton = (props) => {
	return (
		<div
			style={props.style}
			className={`"close-btn" ${props.className}`}
			onClick={props.onClick}
		>
			<CloseIcon fill={props.fill} />
			<span>{props.titleCloseButton}</span>
		</div>
	);
};
