export const AmountIcon = () => {
    return (
        <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.1094 3.28906L13.5859 0.851562C13.4453 0.640625 13.2109 0.5 12.9531 0.5H3.48438C3.22656 0.5 2.96875 0.640625 2.82812 0.851562L1.32812 3.28906C0.625 4.39062 1.09375 5.89062 2.21875 6.35938V11.6797C2.21875 12.1484 2.54688 12.5 2.96875 12.5H13.4688C13.8672 12.5 14.2188 12.1484 14.2188 11.6797V6.35938C15.3438 5.89062 15.7891 4.39062 15.1094 3.28906ZM13.0938 11.375H3.34375V9.125H13.0938V11.375ZM13.0703 8H3.34375V6.5C3.90625 6.40625 4.39844 6.125 4.75 5.72656C5.17188 6.19531 5.78125 6.5 6.48438 6.5C7.16406 6.5 7.77344 6.19531 8.19531 5.72656C8.61719 6.19531 9.25 6.5 9.92969 6.5C10.6328 6.5 11.2422 6.19531 11.6641 5.72656C12.0156 6.125 12.5078 6.40625 13.0703 6.5V8ZM14.1953 4.88281C14.125 5.04688 13.9375 5.32812 13.5625 5.375C13.5156 5.375 13.4453 5.39844 13.3984 5.39844C13.0469 5.39844 12.7422 5.23438 12.5078 4.97656L11.6641 4.03906L10.8438 4.97656C10.6094 5.23438 10.2812 5.375 9.92969 5.375C9.60156 5.375 9.27344 5.23438 9.03906 4.97656L8.21875 4.03906L7.375 4.97656C7.14062 5.23438 6.8125 5.375 6.48438 5.375C6.13281 5.375 5.82812 5.23438 5.59375 4.97656L4.75 4.03906L3.90625 4.97656C3.67188 5.23438 3.36719 5.375 3.01562 5.375C2.96875 5.375 2.89844 5.375 2.85156 5.375C2.47656 5.32812 2.28906 5.04688 2.21875 4.88281C2.10156 4.625 2.05469 4.25 2.26562 3.89844L3.67188 1.625H12.7422L14.1484 3.89844C14.3594 4.25 14.3125 4.625 14.1953 4.88281Z"
                fill="#7A7A7A"
            />
        </svg>
    )
}
