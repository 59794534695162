export const OpenMapIcon = (props) => {
	return (
		<div className={props.className} onClick={props.onClick}>
			<svg
				width='42'
				height='42'
				viewBox='0 0 42 42'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M0 13.875V2.25C0 1.03125 0.9375 0 2.25 0H13.875C14.4375 0 15 0.5625 15 1.125V3.375C15 4.03125 14.4375 4.5 13.875 4.5H4.5V13.875C4.5 14.5312 3.9375 15 3.375 15H1.125C0.46875 15 0 14.5312 0 13.875ZM27 1.125C27 0.5625 27.4688 0 28.125 0H39.75C40.9688 0 42 1.03125 42 2.25V13.875C42 14.5312 41.4375 15 40.875 15H38.625C37.9688 15 37.5 14.5312 37.5 13.875V4.5H28.125C27.4688 4.5 27 4.03125 27 3.375V1.125ZM40.875 27C41.4375 27 42 27.5625 42 28.125V39.75C42 41.0625 40.9688 42 39.75 42H28.125C27.4688 42 27 41.5312 27 40.875V38.625C27 38.0625 27.4688 37.5 28.125 37.5H37.5V28.125C37.5 27.5625 37.9688 27 38.625 27H40.875ZM15 40.875C15 41.5312 14.4375 42 13.875 42H2.25C0.9375 42 0 41.0625 0 39.75V28.125C0 27.5625 0.46875 27 1.125 27H3.375C3.9375 27 4.5 27.5625 4.5 28.125V37.5H13.875C14.4375 37.5 15 38.0625 15 38.625V40.875Z'
					fill='black'
				/>
			</svg>
		</div>
	);
};
