export const RubIcon = (props) => {
    return (
        <svg
            width="9"
            height="11"
            viewBox="0 0 9 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"

        >
            <path
                d="M5.69531 6.88281C7.59375 6.88281 9 5.54688 9 3.55469C9 1.5625 7.59375 0.25 5.69531 0.25H1.78125C1.61719 0.25 1.5 0.390625 1.5 0.53125V5.59375H0.28125C0.117188 5.59375 0 5.73438 0 5.875V6.60156C0 6.76562 0.117188 6.88281 0.28125 6.88281H1.5V7.75H0.28125C0.117188 7.75 0 7.89062 0 8.03125V8.59375C0 8.75781 0.117188 8.875 0.28125 8.875H1.5V10.4688C1.5 10.6328 1.61719 10.75 1.78125 10.75H2.71875C2.85938 10.75 3 10.6328 3 10.4688V8.875H7.21875C7.35938 8.875 7.5 8.75781 7.5 8.59375V8.03125C7.5 7.89062 7.35938 7.75 7.21875 7.75H3V6.88281H5.69531ZM3 1.53906H5.46094C6.70312 1.53906 7.47656 2.28906 7.47656 3.55469C7.47656 4.82031 6.70312 5.59375 5.4375 5.59375H3V1.53906Z"
                fill='#7A7A7A'/>
            />
        </svg>
    )
}
