import { EyeIcon } from 'assets/icons/AppIcons/EyeIcon';
import { LocationIcon } from 'assets/icons/AppIcons/LocationIcon';

import './ViewingBox.scss';

export const ViewingBox = (props) => {
	return (
		<div className='viewing-box'>
			<div onClick={props.onClickOrder} className='icon-location'>
				<EyeIcon fill={props.fill} />
			</div>
			<div onClick={props.onClickGeo} className='icon-location'>
				<LocationIcon fill={props.activeGeo ? 'red' : props.activeGeo} />
			</div>
		</div>
	);
};
