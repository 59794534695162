import { useCallback, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getOrdersList } from 'utils/api/order';

import './DateFilterBox.scss';

export const DateFilterBox = () => {
	const dispatch = useDispatch();
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');

	const handlerStartDateInput = (e) => setStartDate(e.target.value);

	const handlerEndDateInput = (e) => setEndDate(e.target.value);

	const getAllOrdersByDate = useCallback(() => {
		let start = startDate.toString() + ' ' + '00:00:05';
		let end = endDate.toString() + ' ' + '23:59:59';
		dispatch(getOrdersList(start, end));
	});

	return (
		<Card className='date-filter-box'>
			<span className='date-filter-box-text'>Заказы на карте</span>

			<form>
				<label className='date-filter-box-text'>
					с:{' '}
					<input
						type='date'
						value={startDate}
						onChange={handlerStartDateInput}
					/>
				</label>
				<label className='date-filter-box-text'>
					по:{' '}
					<input type='date' value={endDate} onChange={handlerEndDateInput} />{' '}
				</label>
				<Button className='date-filter-box-btn' onClick={getAllOrdersByDate}>
					показать
				</Button>
			</form>
		</Card>
	);
};
