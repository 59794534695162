
export const BikeIcon = (props) => {
    return (
        <svg
            width="32"
            height="27"
            viewBox="0 0 17 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
                border:  props.currentTransportStatus === 'bike' || props.valueType === 2 ? `1px solid ${props.color} ` : '1px solid #ededed',
                padding: '5px',
                borderRadius: '8px',
                boxShadow: props.currentTransportStatus === 'truck' || props.valueType === 2 ? 'none' : '1px 2px #d8d8d8',
                backgroundColor: props.currentTransportStatus === 'bike' ? '#379dd9' : 'transparent',
            }}
        >
            <path
                d="M13 3C12.5781 3 12.1797 3.09375 11.8047 3.25781L11.1953 2.25H13.1875C13.4922 2.25 13.75 2.01562 13.75 1.6875V0.5625C13.75 0.257812 13.4922 0 13.1875 0H11.7812C11.6172 0 11.4062 0.117188 11.3125 0.234375L10.5625 1.24219L9.92969 0.1875C9.88281 0.09375 9.71875 0.0234375 9.625 0H7.84375C7.67969 0 7.5625 0.140625 7.5625 0.28125V0.46875C7.5625 0.632812 7.67969 0.75 7.84375 0.75H9.41406L10.3281 2.25H6.08594C5.6875 1.80469 5.10156 1.5 4 1.5H2.875C2.66406 1.5 2.5 1.66406 2.5 1.875C2.47656 2.08594 2.64062 2.25 2.875 2.25H4C4.5625 2.25 4.9375 2.46094 5.19531 2.71875L4.96094 3.16406C4.70312 3.09375 4.28125 3.02344 4.02344 3.02344C2.35938 3 1 4.33594 1 6C0.976562 7.66406 2.33594 9 4 9C5.38281 9 6.55469 8.0625 6.88281 6.75H8.875C9.10938 6.75 9.27344 6.5625 9.25 6.35156C9.13281 5.01562 9.71875 3.75 10.7734 3L11.1719 3.63281C10.4453 4.17188 9.97656 5.0625 10 6.02344C10 7.66406 11.3359 9 12.9531 9C14.6172 9.02344 15.9531 7.71094 15.9766 6.04688C16.0234 4.38281 14.6641 3.02344 13 3ZM11.8281 0.65625H13.0938V1.59375H11.125L11.8281 0.65625ZM4 8.25C2.75781 8.25 1.75 7.24219 1.75 6C1.75 4.78125 2.75781 3.75 4 3.75C4.16406 3.75 4.42188 3.79688 4.58594 3.84375L3.27344 6.21094C3.15625 6.44531 3.32031 6.75 3.625 6.75H6.10938C5.80469 7.64062 4.96094 8.25 4 8.25ZM8.5 6H4.25781L5.89844 3H9.625C8.89844 3.82031 8.5 4.89844 8.5 6ZM12.9062 8.25C11.7344 8.20312 10.7969 7.26562 10.75 6.09375C10.7031 5.36719 11.0312 4.71094 11.5469 4.26562L12.9062 6.46875C12.9766 6.60938 13.1641 6.65625 13.2812 6.5625L13.4453 6.46875C13.5859 6.39844 13.6328 6.21094 13.5391 6.09375L12.2031 3.89062C12.4141 3.82031 12.7656 3.75 12.9766 3.75H13C14.2188 3.75 15.25 4.78125 15.25 6C15.25 7.28906 14.1719 8.32031 12.9062 8.25Z"
                fill={props.currentTransportStatus === 'bike' || props.color ? props.fill : 'black'}/>
        </svg>
    );
}