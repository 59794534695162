import { CarIcon } from 'assets/icons/AppIcons/CarIcon';
import { CarNumberBox } from 'components/CarNumberBox/CarNumberBox';
import { TransportDataBox } from 'components/TransportDataBox/TransportDataBox';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import './CardTransport.scss';

export const CardTransport = (props) => {
	const [counterSession, setCounterSession] = useState(0);

	useEffect(() => {
		if (props.sessions) {
			handlerCounterSession();
		}
	}, [props]);

	const id = props.data.Id;

	const handlerCounterSession = () => {
		let counter = 0;
		props.sessions?.map((el) => {
			if (el.transport.Id === id) {
				counter += 1;
			}
		});
		setCounterSession(counter);
	};

	return (
		<div className='card-transport-content'>
			<div className='card-transport-content-title'>
				<div className='transport-driver-wrapper'>
					<div>
						<CarIcon fill={props.traceColor} />
						<span>{props.driverName}</span>
					</div>
					<CarNumberBox carNumber={props.licenseId} />
					<span className='model-car'>{props.transportModel}</span>
				</div>
				<Button className='action__add-btn' onClick={props.onClick}>
					Выбрать
				</Button>
			</div>
			<TransportDataBox
				data={props.data}
				counterSession={counterSession}
				activity={props.activity ? props.activity : null}
			/>
		</div>
	);
};
