import React, {useEffect, useState} from 'react';
import './SwitchTransportModalBox.scss';
import {PassengerCarIcon} from "../../assets/icons/AppIcons/PassengerCarIcon";
import {TruckIcon} from "../../assets/icons/AppIcons/TruckIcon";
import {BikeIcon} from "../../assets/icons/AppIcons/BikeIcon";
import {HumanIcon} from "../../assets/icons/AppIcons/HumanIcon";
import {useStore} from "react-redux";

export const SwitchTransportModalBox = ({ transportType, color, onChangeTransportType}) => {
    const [selectedTransportType, setSelectedTransportType] = useState(transportType);

    const handleIconClick = (type) => {
        setSelectedTransportType(type);
        onChangeTransportType(type);
    }
    return (
        <div className="switch-transport-modal-box">
            <div className="transport-icons">
                <div className="transport-icon" onClick={() => handleIconClick(0)}>
                    <PassengerCarIcon fill={transportType === 0 || selectedTransportType === 0 ? color : 'black'} valueType={transportType} color={color}/>
                </div>
                <div className="transport-icon" onClick={() => handleIconClick(1)}>
                    <TruckIcon fill={transportType === 1 || selectedTransportType === 1 ? color : 'black'} valueType={transportType} color={color}/>
                </div>
                <div className="transport-icon" onClick={() => handleIconClick(2)}>
                    <BikeIcon fill={transportType === 2 || selectedTransportType === 2 ? color : 'black'} valueType={transportType} color={color}/>
                </div>
                <div className="transport-icon" onClick={() => handleIconClick(3)}>
                    <HumanIcon fill={transportType === 3 || selectedTransportType === 3 ? color : 'black'} valueType={transportType} color={color}/>
                </div>
            </div>
        </div>
    );
}

