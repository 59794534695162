export const PhoneIcon = () => {
	return (
		<svg
			width='8'
			height='13'
			viewBox='0 0 8 13'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M6.375 0.5H1.125C0.492188 0.5 0 1.01562 0 1.625V11.375C0 12.0078 0.492188 12.5 1.125 12.5H6.375C6.98438 12.5 7.5 12.0078 7.5 11.375V1.625C7.5 1.01562 6.98438 0.5 6.375 0.5ZM6.23438 11.375H1.26562C1.17188 11.375 1.125 11.3281 1.125 11.2344V1.76562C1.125 1.69531 1.17188 1.625 1.26562 1.625H6.23438C6.30469 1.625 6.375 1.69531 6.375 1.76562V11.2344C6.375 11.3281 6.30469 11.375 6.23438 11.375ZM4.59375 10.625C4.73438 10.625 4.875 10.5078 4.875 10.3438V10.1562C4.875 10.0156 4.73438 9.875 4.59375 9.875H2.90625C2.74219 9.875 2.625 10.0156 2.625 10.1562V10.3438C2.625 10.5078 2.74219 10.625 2.90625 10.625H4.59375Z'
				fill='#7A7A7A'
			/>
		</svg>
	);
};
