import React from "react"

export const EyeIcon = (props) => {
    return (
        <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.875 6.625C8.83203 6.625 9.625 5.85938 9.625 4.875C9.625 3.91797 8.83203 3.125 7.875 3.125C7.84766 3.125 7.82031 3.15234 7.79297 3.15234C7.82031 3.26172 7.875 3.45312 7.875 3.58984C7.875 4.30078 7.27344 4.90234 6.5625 4.90234C6.42578 4.90234 6.23438 4.84766 6.125 4.82031C6.125 4.84766 6.125 4.875 6.125 4.875C6.125 5.85938 6.89062 6.625 7.875 6.625ZM15.6406 5.36719C14.1641 2.46875 11.2109 0.5 7.875 0.5C4.51172 0.5 1.55859 2.46875 0.0820312 5.36719C0.0273438 5.47656 0 5.64062 0 5.77734C0 5.88672 0.0273438 6.05078 0.0820312 6.16016C1.55859 9.05859 4.51172 11 7.875 11C11.2109 11 14.1641 9.05859 15.6406 6.16016C15.6953 6.05078 15.7227 5.88672 15.7227 5.75C15.7227 5.64062 15.6953 5.47656 15.6406 5.36719ZM7.875 1.375C9.78906 1.375 11.375 2.96094 11.375 4.875C11.375 6.81641 9.78906 8.375 7.875 8.375C5.93359 8.375 4.375 6.81641 4.375 4.875C4.375 2.96094 5.93359 1.40234 7.875 1.375ZM7.875 10.125C4.92188 10.125 2.24219 8.45703 0.875 5.75C1.53125 4.46484 3.11719 2.87891 4.40234 2.22266C3.82812 2.96094 3.5 3.89062 3.5 4.875C3.5 7.30859 5.44141 9.25 7.875 9.25C10.2812 9.25 12.25 7.30859 12.25 4.875C12.25 3.89062 11.8945 2.96094 11.3203 2.22266C12.6055 2.87891 14.1914 4.46484 14.875 5.75C13.4805 8.45703 10.8008 10.125 7.875 10.125Z"
                fill={props.fill ? props.fill : "#D8D8D8"}
            />
        </svg>
    )
}
