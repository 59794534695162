export const ClearIcon = (props) => {
	return (
		<svg
			width='10'
			height='10'
			viewBox='0 0 10 10'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M6.27734 4.75L9.09375 1.96094L9.66797 1.38672C9.75 1.30469 9.75 1.16797 9.66797 1.05859L9.06641 0.457031C8.95703 0.375 8.82031 0.375 8.73828 0.457031L5.375 3.84766L1.98438 0.457031C1.90234 0.375 1.76562 0.375 1.65625 0.457031L1.05469 1.05859C0.972656 1.16797 0.972656 1.30469 1.05469 1.38672L4.44531 4.75L1.05469 8.14062C0.972656 8.22266 0.972656 8.35938 1.05469 8.46875L1.65625 9.07031C1.76562 9.15234 1.90234 9.15234 1.98438 9.07031L5.375 5.67969L8.16406 8.49609L8.73828 9.07031C8.82031 9.15234 8.95703 9.15234 9.06641 9.07031L9.66797 8.46875C9.75 8.35938 9.75 8.22266 9.66797 8.14062L6.27734 4.75Z'
				fill={props.fill ? props.fill : 'black'}
			/>
		</svg>
	);
};
