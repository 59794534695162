import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	data: null,
};

const orderSlice = createSlice({
	name: 'order',
	initialState,
	reducers: {
		setOrder(state, action) {
			state.data = action.payload;
		},
		clearOrder(state, action) {
			state.data = action.payload;
		},
	},
});

export const { setOrder, clearOrder } = orderSlice.actions;

export default orderSlice.reducer;
