import { axios } from "core"
import { setCustomer } from "store/slices/customerSlice"

export const getAllCustomers = () => {
    // console.log("res getAllCustomers access_token", access_token)
    let access_token = localStorage.getItem('AccessToken');
    return async (dispatch) => {
        try {
            // const res = await axios.get("/organization/customer/all", {
            const res = await axios.get(
                `/logistics/customers/all`,
                {
                    headers: {
                        Authorization: `${access_token}`,
                    },
                }
            )
            console.log("res getAllCustomers", res)
            dispatch(setCustomer(res.data.customers))
        } catch (error) {
            console.log("error", error.message)
        }
    }
}
