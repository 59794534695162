import { RadioInput } from 'components';
import { useState } from 'react';

import { Card } from 'react-bootstrap';
import './SwitchBox.scss';

export const SwitchBox = (props) => {
	const [value, setValue] = useState(props.active);

	const handlerChange = (e) => {
		setValue(e.target.value);
		props.onChangeStatus(e.target.value);
	}

	return (
		// <div className='switch-box-wrapper'>
		<Card className='switch-box-wrapper-card'>
			{props?.status?.map((el, idx) => (
				<RadioInput
					key={idx}
					value={el.status}
					text={el.text}
					onChange={handlerChange}
					checked={value === el.status}
					badge={props.badge}
					counter={el.counter}
				/>
			))}
		</Card>
		// </div>
	);
};
