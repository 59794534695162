export const DirectoryIcon = (props) => {
	return (
		<svg
			width='23'
			height='19'
			viewBox='0 0 46 38'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M6.25 0.25H1.75C0.90625 0.25 0.25 1 0.25 1.75V6.25C0.25 7.09375 0.90625 7.75 1.75 7.75H6.25C7 7.75 7.75 7.09375 7.75 6.25V1.75C7.75 1 7 0.25 6.25 0.25ZM6.25 15.25H1.75C0.90625 15.25 0.25 16 0.25 16.75V21.25C0.25 22.0938 0.90625 22.75 1.75 22.75H6.25C7 22.75 7.75 22.0938 7.75 21.25V16.75C7.75 16 7 15.25 6.25 15.25ZM6.25 30.25H1.75C0.90625 30.25 0.25 31 0.25 31.75V36.25C0.25 37.0938 0.90625 37.75 1.75 37.75H6.25C7 37.75 7.75 37.0938 7.75 36.25V31.75C7.75 31 7 30.25 6.25 30.25ZM45.25 32.5H13.75C13.2812 32.5 13 32.875 13 33.25V34.75C13 35.2188 13.2812 35.5 13.75 35.5H45.25C45.625 35.5 46 35.2188 46 34.75V33.25C46 32.875 45.625 32.5 45.25 32.5ZM45.25 2.5H13.75C13.2812 2.5 13 2.875 13 3.25V4.75C13 5.21875 13.2812 5.5 13.75 5.5H45.25C45.625 5.5 46 5.21875 46 4.75V3.25C46 2.875 45.625 2.5 45.25 2.5ZM45.25 17.5H13.75C13.2812 17.5 13 17.875 13 18.25V19.75C13 20.2188 13.2812 20.5 13.75 20.5H45.25C45.625 20.5 46 20.2188 46 19.75V18.25C46 17.875 45.625 17.5 45.25 17.5Z'
				fill={props.fill}
			/>
		</svg>
	);
};
