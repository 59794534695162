import { SearchIcon } from 'assets/icons/AppIcons/SearchIcon';
import { Form, InputGroup } from 'react-bootstrap';

import './FormInput.scss';

export const FormInput = (props) => {
	return (
		<div className={`form-input mb-3 ${props.className}`}>
			{props.label && (
				<div className='form-input__label'>
					<Form.Label className='form-input__label-text'>
						{props.label}
					</Form.Label>
				</div>
			)}
			{props.icon && (
				<InputGroup.Text className='form-input__icon'>
					<SearchIcon />
				</InputGroup.Text>
			)}
			<Form.Control
				className={props.icon ? 'icon-input' : 'form-input'}
				placeholder={props.placeholder}
				value={props.value}
				onChange={props.onChange}
				type={props.type}
				pattern={props.pattern}
				style={props.style}
				onClick={props.onClick}
			/>
		</div>
	);
};
