import { CloseButton } from 'components/CloseButton/CloseButton';
import { CardTransport } from './../CardTransport/CardTransport';
import './RightModalTransports.scss';

export const RightModalTransports = (props) => {
	return (
		<div className={props.visible ? 'right-modal' : 'hide-modal'}>
			<CloseButton
				onClick={() => props.onHide()}
				className='close-btn'
				titleCloseButton={props.titleCloseButton}
			/>
			<div className='right-modal-content right-modal-content-transport'>
				{props?.allTransports?.map((el, index) => {
					return (
						<CardTransport
							key={index}
							traceColor={el.traceColor}
							driverName={el.driver.driverName}
							licenseId={el.licenseId}
							transportModel={el.transportModel}
							data={el}
							onClick={() => props.onClick(el)}
							sessions={props.sessions}
						/>
					);
				})}
			</div>
		</div>
	);
};
