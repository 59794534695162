import { Accordion, Card } from 'react-bootstrap';
import { ProfileIcon } from '../../assets/icons/AppIcons/ProfileIcon';

import { AmountIcon } from '../../assets/icons/AppIcons/AmountIcon';
import { EyeIcon } from '../../assets/icons/AppIcons/EyeIcon';
import { Graph } from '../../assets/icons/AppIcons/Graph';
import { LocationIcon } from '../../assets/icons/AppIcons/LocationIcon';
import { MapAgent } from '../../assets/icons/AppIcons/MapAgent';
import { RubIcon } from '../../assets/icons/AppIcons/RubIcon';
import { ScuIcon } from '../../assets/icons/AppIcons/ScuIcon';
import { WeightIcon } from '../../assets/icons/AppIcons/WeightIcon';
import './AccordionBoxAgent.scss';

export const AccordionBoxAgent = ({ allCustomers }) => {
	return (
		<Card className='customers-data' style={{ width: '100%' }}>
			<div className='accordion'>
				<Accordion defaultActiveKey='0'>
					{allCustomers.map((dataAgent) => (
						<Accordion.Item key={dataAgent.Id}>
							<Accordion.Header>
								<div className='agent-header'>
									<div className='agent-title'>
										<div className='icon'>
											<ProfileIcon />
										</div>
										<div className='item-title'>{dataAgent?.abbrName}</div>
									</div>
									<div className='agent-subtitle'>
										<div className='item-subtitle-box'>
											<div>
												<RubIcon />
											</div>
											<div className='item-subtitle'>
												{dataAgent?.totalOrdersSum}
											</div>
										</div>
										<div className='item-subtitle-box'>
											<div>
												<ScuIcon />
											</div>
											<div className='item-subtitle'>
												{dataAgent?.totalOrdersVolume} SCU
											</div>
										</div>
										<div className='item-subtitle-box'>
											<div>
												<WeightIcon />
											</div>
											<div className='item-subtitle'>
												{dataAgent?.totalOrdersWeight}
											</div>
										</div>
										<div className='item-subtitle-box'>
											<div>
												<AmountIcon />
											</div>
											<div className='item-subtitle'>
												{dataAgent?.totalOrdersNum}
											</div>
										</div>
									</div>
								</div>
							</Accordion.Header>
							{dataAgent?.deliveryPoints[0] ? (
								<Accordion.Body>
									<div className='agent-body'>
										<div className='item-header'>
											{dataAgent?.deliveryPoints[0]?.companyName}
										</div>
										<div className='item-info'>
											<div className='info-icon'>
												<MapAgent />
											</div>
											<div className='info-address'>
												{dataAgent?.deliveryPoints[0]?.address}
											</div>
										</div>
										<div className='info-icons'>
											<div>
												<Graph />
											</div>
											<div>
												<EyeIcon />
											</div>
											<div>
												<LocationIcon />
											</div>
										</div>
									</div>
								</Accordion.Body>
							) : (
								''
							)}
						</Accordion.Item>
					))}
					(
				</Accordion>
			</div>
		</Card>
	);
};
