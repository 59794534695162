export const TruckTransportIcon = (props) => {
    return (
        <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.0625 10.375H16.625V7.42188C16.625 7.09375 16.4609 6.73828 16.2148 6.49219L13.5078 3.78516C13.2617 3.53906 12.9062 3.375 12.5781 3.375H11.375V2.0625C11.375 1.35156 10.7734 0.75 10.0625 0.75H1.3125C0.574219 0.75 0 1.35156 0 2.0625V10.8125C0 11.5508 0.574219 12.125 1.3125 12.125H1.75C1.75 13.5742 2.92578 14.75 4.375 14.75C5.82422 14.75 7 13.5742 7 12.125H10.5C10.5 13.5742 11.6758 14.75 13.125 14.75C14.5742 14.75 15.75 13.5742 15.75 12.125H17.0625C17.2812 12.125 17.5 11.9336 17.5 11.6875V10.8125C17.5 10.5938 17.2812 10.375 17.0625 10.375ZM4.375 13.4375C3.63672 13.4375 3.0625 12.8633 3.0625 12.125C3.0625 11.4141 3.63672 10.8125 4.375 10.8125C5.08594 10.8125 5.6875 11.4141 5.6875 12.125C5.6875 12.8633 5.08594 13.4375 4.375 13.4375ZM13.125 13.4375C12.3867 13.4375 11.8125 12.8633 11.8125 12.125C11.8125 11.4141 12.3867 10.8125 13.125 10.8125C13.8359 10.8125 14.4375 11.4141 14.4375 12.125C14.4375 12.8633 13.8359 13.4375 13.125 13.4375ZM15.3125 7.75H11.375V4.6875H12.5781L15.3125 7.42188V7.75Z"
                fill={props.fill || "#378FC9"}
            />
        </svg>
    )
}
