export const SearchIcon = () => {
    return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.9062 11.7969L8.88281 8.77344C8.83594 8.72656 8.76562 8.70312 8.69531 8.70312H8.4375C9.25781 7.8125 9.75 6.66406 9.75 5.375C9.75 2.70312 7.54688 0.5 4.875 0.5C2.17969 0.5 0 2.70312 0 5.375C0 8.07031 2.17969 10.25 4.875 10.25C6.14062 10.25 7.3125 9.75781 8.17969 8.96094V9.19531C8.17969 9.28906 8.20312 9.35938 8.25 9.40625L11.2734 12.4297C11.3906 12.5469 11.5547 12.5469 11.6719 12.4297L11.9062 12.1953C12.0234 12.0781 12.0234 11.9141 11.9062 11.7969ZM4.875 9.5C2.57812 9.5 0.75 7.67188 0.75 5.375C0.75 3.10156 2.57812 1.25 4.875 1.25C7.14844 1.25 9 3.10156 9 5.375C9 7.67188 7.14844 9.5 4.875 9.5Z"
                fill="#C3C3C3"
            />
        </svg>
    )
}
