export const ReloadIcon = (props) => {
    return (
        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.5312 0.6875H11.2969C11.1328 0.6875 11.0156 0.828125 11.0156 0.96875V3.57031C9.98438 1.85938 8.13281 0.6875 6 0.6875C2.88281 0.6875 0.328125 3.14844 0.1875 6.21875C0.164062 6.38281 0.304688 6.5 0.46875 6.5H0.703125C0.84375 6.5 0.960938 6.40625 0.984375 6.24219C1.125 3.61719 3.30469 1.48438 6 1.48438C7.92188 1.48438 9.60938 2.60938 10.4531 4.20312H7.78125C7.61719 4.20312 7.5 4.34375 7.5 4.48438V4.71875C7.5 4.88281 7.61719 5 7.78125 5H11.5312C11.6719 5 11.8125 4.88281 11.8125 4.71875V0.96875C11.8125 0.828125 11.6719 0.6875 11.5312 0.6875ZM11.5078 6.5H11.2734C11.1328 6.5 11.0156 6.61719 10.9922 6.78125C10.8516 9.42969 8.67188 11.5156 6 11.5156C4.05469 11.5156 2.36719 10.4375 1.52344 8.79688H4.21875C4.35938 8.79688 4.5 8.67969 4.5 8.51562V8.28125C4.5 8.14062 4.35938 8 4.21875 8H0.46875C0.304688 8 0.1875 8.14062 0.1875 8.28125V12.0312C0.1875 12.1953 0.304688 12.3125 0.46875 12.3125H0.703125C0.84375 12.3125 0.984375 12.1953 0.984375 12.0312V9.45312C1.99219 11.1641 3.84375 12.3125 6 12.3125C9.09375 12.3125 11.6484 9.875 11.7891 6.80469C11.8125 6.64062 11.6719 6.5 11.5078 6.5Z"
                fill={props.fill ? props.fill : "black"}
            />
        </svg>
    )
}
