import React from "react"
import { Card } from "react-bootstrap"
import { Link } from "react-router-dom"

import "./UserMenu.scss"

const UserMenu = (props) => {
    return (
        <Card className={`user-menu ${props.visible ? "visible" : "hidden"}`}>
            <Card.Text>
                <Link className="user-menu__link">Настройки</Link>
            </Card.Text>
            <Card.Text>
                <Link className="user-menu__link">Изменить пароль</Link>
            </Card.Text>
        </Card>
    )
}

export default UserMenu
