export const PercentIcon = (props) => {
	return (
		<svg
			width='10'
			height='10'
			viewBox='0 0 10 10'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M2.34 0.312C2.932 0.312 3.38 0.548 3.684 1.02C3.988 1.484 4.14 2.14 4.14 2.988C4.14 3.836 3.992 4.5 3.696 4.98C3.408 5.452 2.956 5.688 2.34 5.688C1.964 5.688 1.644 5.58 1.38 5.364C1.116 5.148 0.916 4.84 0.78 4.44C0.652 4.032 0.588 3.548 0.588 2.988C0.588 2.14 0.728 1.484 1.008 1.02C1.288 0.548 1.732 0.312 2.34 0.312ZM2.34 1.056C2.036 1.056 1.812 1.22 1.668 1.548C1.532 1.868 1.464 2.348 1.464 2.988C1.464 3.628 1.532 4.112 1.668 4.44C1.812 4.768 2.036 4.932 2.34 4.932C2.652 4.932 2.884 4.772 3.036 4.452C3.188 4.124 3.264 3.636 3.264 2.988C3.264 2.348 3.188 1.868 3.036 1.548C2.884 1.22 2.652 1.056 2.34 1.056ZM7.824 0.431999L3.072 9H2.148L6.9 0.431999H7.824ZM7.584 3.744C7.976 3.744 8.304 3.852 8.568 4.068C8.832 4.276 9.032 4.58 9.168 4.98C9.312 5.372 9.384 5.852 9.384 6.42C9.384 7.268 9.236 7.932 8.94 8.412C8.652 8.884 8.2 9.12 7.584 9.12C7.208 9.12 6.888 9.012 6.624 8.796C6.36 8.58 6.16 8.272 6.024 7.872C5.896 7.464 5.832 6.98 5.832 6.42C5.832 5.572 5.972 4.916 6.252 4.452C6.532 3.98 6.976 3.744 7.584 3.744ZM7.584 4.5C7.28 4.5 7.056 4.66 6.912 4.98C6.776 5.3 6.708 5.78 6.708 6.42C6.708 7.068 6.776 7.556 6.912 7.884C7.056 8.204 7.28 8.364 7.584 8.364C7.896 8.364 8.128 8.204 8.28 7.884C8.432 7.564 8.508 7.076 8.508 6.42C8.508 5.78 8.432 5.3 8.28 4.98C8.128 4.66 7.896 4.5 7.584 4.5Z'
				fill={props.fill ? props.fill : '#7A7A7A'}
			/>
		</svg>
	);
};
