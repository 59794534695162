export const HamburgerIcon = () => {
	return (
		<svg
			width='24'
			height='15'
			viewBox='0 0 48 30'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M0.75 3H41.25C41.625 3 42 2.71875 42 2.25V0.75C42 0.375 41.625 0 41.25 0H0.75C0.28125 0 0 0.375 0 0.75V2.25C0 2.71875 0.28125 3 0.75 3ZM47.25 13.5H6.75C6.28125 13.5 6 13.875 6 14.25V15.75C6 16.2188 6.28125 16.5 6.75 16.5H47.25C47.625 16.5 48 16.2188 48 15.75V14.25C48 13.875 47.625 13.5 47.25 13.5ZM41.25 27H0.75C0.28125 27 0 27.375 0 27.75V29.25C0 29.7188 0.28125 30 0.75 30H41.25C41.625 30 42 29.7188 42 29.25V27.75C42 27.375 41.625 27 41.25 27Z'
				fill='black'
			/>
		</svg>
	);
};
