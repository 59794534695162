import { MapBox } from 'components';
import { PageView } from 'components/PageView/PageView';
import { Card } from 'react-bootstrap';

const MapPage = () => {
	return (
		<PageView>
			<Card style={{ width: '100%', height: '100%' }}>
				<MapBox />
			</Card>
		</PageView>
	);
};

export default MapPage;
