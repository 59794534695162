export const RateIcon = () => {
	return (
		<svg
			width='86'
			height='13'
			viewBox='0 0 86 13'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M6.07031 0.921875L4.54688 4.03906L1.10156 4.53125C0.492188 4.625 0.257812 5.375 0.703125 5.82031L3.16406 8.23438L2.57812 11.6328C2.48438 12.2422 3.14062 12.7109 3.67969 12.4297L6.75 10.8125L9.79688 12.4297C10.3359 12.7109 10.9922 12.2422 10.8984 11.6328L10.3125 8.23438L12.7734 5.82031C13.2188 5.375 12.9844 4.625 12.375 4.53125L8.95312 4.03906L7.40625 0.921875C7.14844 0.382812 6.35156 0.359375 6.07031 0.921875Z'
				fill='#378FC9'
			/>
			<path
				d='M24.0703 0.921875L22.5469 4.03906L19.1016 4.53125C18.4922 4.625 18.2578 5.375 18.7031 5.82031L21.1641 8.23438L20.5781 11.6328C20.4844 12.2422 21.1406 12.7109 21.6797 12.4297L24.75 10.8125L27.7969 12.4297C28.3359 12.7109 28.9922 12.2422 28.8984 11.6328L28.3125 8.23438L30.7734 5.82031C31.2188 5.375 30.9844 4.625 30.375 4.53125L26.9531 4.03906L25.4062 0.921875C25.1484 0.382812 24.3516 0.359375 24.0703 0.921875Z'
				fill='#378FC9'
			/>
			<path
				d='M42.0703 0.921875L40.5469 4.03906L37.1016 4.53125C36.4922 4.625 36.2578 5.375 36.7031 5.82031L39.1641 8.23438L38.5781 11.6328C38.4844 12.2422 39.1406 12.7109 39.6797 12.4297L42.75 10.8125L45.7969 12.4297C46.3359 12.7109 46.9922 12.2422 46.8984 11.6328L46.3125 8.23438L48.7734 5.82031C49.2188 5.375 48.9844 4.625 48.375 4.53125L44.9531 4.03906L43.4062 0.921875C43.1484 0.382812 42.3516 0.359375 42.0703 0.921875Z'
				fill='#378FC9'
			/>
			<path
				d='M65.9062 4.53125L62.4844 4.03906L60.9375 0.921875C60.7969 0.640625 60.5391 0.5 60.2578 0.5C60 0.5 59.7422 0.640625 59.6016 0.921875L58.0547 4.03906L54.6328 4.53125C54.0234 4.625 53.7656 5.375 54.2109 5.82031L56.6953 8.23438L56.1094 11.6328C56.0156 12.125 56.4141 12.5 56.8594 12.5C56.9766 12.5 57.0938 12.4766 57.2109 12.4297L60.2578 10.8125L63.3281 12.4297C63.4453 12.4766 63.5625 12.5 63.6797 12.5C64.125 12.5 64.5 12.125 64.4297 11.6328L63.8438 8.23438L66.3281 5.82031C66.7734 5.375 66.5156 4.625 65.9062 4.53125ZM63.0469 7.41406L62.625 7.83594L62.7188 8.42188L63.1875 11.0703L60.7969 9.82812L60.2578 9.54688L60.2812 2.11719L61.4766 4.53125L61.7344 5.07031L62.3203 5.14062L64.9922 5.53906L63.0469 7.41406Z'
				fill='#378FC9'
			/>
			<path
				d='M84.375 4.53125L80.9531 4.03906L79.4062 0.921875C79.1484 0.382812 78.3516 0.359375 78.0703 0.921875L76.5469 4.03906L73.1016 4.53125C72.4922 4.625 72.2578 5.375 72.7031 5.82031L75.1641 8.23438L74.5781 11.6328C74.4844 12.2422 75.1406 12.7109 75.6797 12.4297L78.75 10.8125L81.7969 12.4297C82.3359 12.7109 82.9922 12.2422 82.8984 11.6328L82.3125 8.23438L84.7734 5.82031C85.2188 5.375 84.9844 4.625 84.375 4.53125ZM81.0938 7.83594L81.6562 11.0703L78.75 9.54688L75.8203 11.0703L76.3828 7.83594L74.0156 5.53906L77.2734 5.07031L78.75 2.11719L80.2031 5.07031L83.4609 5.53906L81.0938 7.83594Z'
				fill='#378FC9'
			/>
		</svg>
	);
};
