import { AmountIcon } from 'assets/icons/AppIcons/AmountIcon';
import { ClockIcon } from 'assets/icons/AppIcons/ClockIcon';
import { PercentIcon } from 'assets/icons/AppIcons/PercentIcon';
import { RoadIcon } from 'assets/icons/AppIcons/RoadIcon';
import { WeightIcon } from 'assets/icons/AppIcons/WeightIcon';

import { useEffect, useState } from 'react';
import './DriverDataBox.scss';

export const DriverDataBox = (props) => {
	const data = props.data;
	const [sumWeight, setSumWeight] = useState(0);

	useEffect(() => {
		sumTotalAmount(data);
	}, [props]);

	const sumTotalAmount = (arr) => {
		let res = 0;
		arr?.orders?.map((el) => {
			return (res += el.totalWeight);
		});
		setSumWeight(res.toFixed(2));
	};

	const weightColor = () => {
		return sumWeight > props.data.transport.loadCapacity ? 'red' : null;
	};

	const percentCounter = () => {
		let res = (sumWeight / props.data.transport.loadCapacity) * 100;
		return res.toFixed(0);
	};

	// console.log('props.data', props.data.transport.loadCapacity);

	return (
		<div className='box'>
			<div className='box_item'>
				<AmountIcon />
				<label className='box_item-text'>{`${data.orders.length} шт`}</label>
			</div>
			<div className='box_item'>
				<RoadIcon />
				<label className='box_item-text'>37,8 км</label>
			</div>
			<div className='box_item'>
				<ClockIcon />
				<label className='box_item-text'>3,2 ч</label>
			</div>
			<div className='box_item'>
				<WeightIcon fill={weightColor()} />
				<label className='box_item-text' style={{ color: weightColor() }}>
					{`${sumWeight} кг`}{' '}
				</label>
			</div>
			<div className='box_item'>
				<label className='box_item-text' style={{ color: weightColor() }}>
					{percentCounter()}
				</label>
				<PercentIcon fill={weightColor()} />
			</div>
			{props.delButton && (
				<div className='box_item del_button' onClick={props.delTransport}>
					<span>Удалить</span>
					{/* <CloseIcon width='12' height='12' /> */}
				</div>
			)}
		</div>
	);
};
