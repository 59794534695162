export const ShareIcon = () => {
    return (
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.7266 7.14844L8.60156 1.02344C8.4375 0.859375 8.21875 0.75 8 0.75C7.75391 0.75 7.53516 0.859375 7.37109 1.02344L1.24609 7.14844C0.890625 7.47656 0.890625 8.05078 1.24609 8.37891L7.37109 14.5039C7.53516 14.668 7.75391 14.75 8 14.75C8.21875 14.75 8.4375 14.668 8.60156 14.5039L14.7266 8.37891C15.082 8.05078 15.082 7.47656 14.7266 7.14844ZM7.97266 13.875L1.875 7.75L7.97266 1.65234C7.97266 1.65234 7.97266 1.625 8 1.625V1.65234L14.0977 7.77734L7.97266 13.875ZM10.0234 6.875H6.6875C5.94922 6.875 5.375 7.47656 5.375 8.1875V9.28125C5.375 9.41797 5.45703 9.5 5.59375 9.5H6.03125C6.14062 9.5 6.25 9.41797 6.25 9.28125V8.1875C6.25 7.96875 6.44141 7.75 6.6875 7.75H10.0234L8.54688 9.14453C8.46484 9.22656 8.4375 9.36328 8.51953 9.44531L8.82031 9.77344C8.90234 9.85547 9.03906 9.85547 9.12109 9.77344L11.2812 7.80469C11.418 7.69531 11.5 7.50391 11.5 7.3125C11.5 7.14844 11.418 6.95703 11.2812 6.84766L9.12109 4.87891C9.03906 4.79688 8.90234 4.79688 8.82031 4.87891L8.51953 5.20703C8.4375 5.28906 8.46484 5.42578 8.54688 5.50781L10.0234 6.875Z"
                fill="#D8D8D8"
            />
        </svg>
    )
}
