import { FormInput } from 'components/FormInput/FormInput';
import { IconButton } from 'components/IconButton/IconButton';

import { ClearIcon } from 'assets/icons/AppIcons/ClearIcon';
import { PlusIcon } from 'assets/icons/AppIcons/PlusIcon';
import { CardTransport } from 'components/CardTransport/CardTransport';
import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import './FormSelect.scss';
import {CardDriver} from "../CardDriver/CardDriver";

export const FormSelect = (props) => {
	const [visibleOptions, setVisibleOptions] = useState(false);
	const [valueCheckTransport, setValueCheckTransport] = useState('');
	const [valueCheckDriver, setValueCheckDriver] = useState('');

	useEffect(() => {
		setValueCheckTransport(props.value);
		setValueCheckDriver(props.value)
		return () => setVisibleOptions(false)
	}, [props]);

	const handlerVisibleOptions = () => setVisibleOptions(true);

	const handlerHideOptions = () => setVisibleOptions(false);

	const handlerCheckTransport = (val) => {
		setValueCheckTransport(val?.licenseId);
		props.handlerCheckTransport(val);
		handlerHideOptions();
	};

	const handlerCheckDriver = (val) => {
		setValueCheckDriver(val?.Id);
		props.handlerCheckDriver(val);
		handlerHideOptions();
	};

	const handlerChangeInput = (e) => setValueCheckTransport(e.target.value);

	const clearInputValue = () => setValueCheckTransport('');

	return (
		<div className='form-app-select'>
			<div className='form-app-select-fragment'>
				<FormInput
					label={props.label}
					placeholder={props.placeholder}
					type={props.type}
					className='form-app-select-fragment-input'
					value={valueCheckTransport || valueCheckDriver}
					onChange={props.onChange ? props.onChange : handlerChangeInput}
				/>
				{visibleOptions && (
					<Card className={props.allTransports? 'form-app-select-fragment-options' : 'form-app-select-fragment-options-drivers'}  >
						{props.allTransports?.map((el, index) => (
							<CardTransport
								key={index}
								traceColor={el.traceColor}
								driverName={el.driver.driverName}
								licenseId={el.licenseId}
								transportModel={el.transportModel}
								data={el}
								onClick={() => handlerCheckTransport(el)}
								sessions={props.sessions}
								activity={el.driver.driverName ? 'На маршруте' : 'В гараже'}
							/>
						))}
						{props.allDrivers?.map((el, index) => (
							<CardDriver
								key={index}
								data={el}
								onClick={() => handlerCheckDriver(el)}
							/>
						))}
					</Card>
				)}
			</div>
			<IconButton
				icon={<PlusIcon fill='#1E1E1E' width='32' height='27' />}
				className='form-app-select-btn'
				onClick={handlerVisibleOptions}
			/>
			<IconButton
				icon={<ClearIcon fill='#1E1E1E' width='32' height='27' />}
				className='form-app-select-btn'
				onClick={valueCheckTransport ? clearInputValue : handlerHideOptions}
			/>
		</div>
	);
};
