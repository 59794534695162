export const MailIcon = (props) => {
	return (
		<svg
			width='12'
			height='10'
			viewBox='0 0 12 10'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className={props.className}
		>
			<path
				d='M10.875 0H1.125C0.492188 0 0 0.515625 0 1.125V7.875C0 8.50781 0.492188 9 1.125 9H10.875C11.4844 9 12 8.50781 12 7.875V1.125C12 0.515625 11.4844 0 10.875 0ZM10.875 1.125V2.08594C10.3359 2.53125 9.49219 3.1875 7.71094 4.59375C7.3125 4.89844 6.53906 5.64844 6 5.625C5.4375 5.64844 4.66406 4.89844 4.26562 4.59375C2.48438 3.1875 1.64062 2.53125 1.125 2.08594V1.125H10.875ZM1.125 7.875V3.53906C1.64062 3.96094 2.41406 4.57031 3.5625 5.48438C4.07812 5.88281 4.99219 6.77344 6 6.75C6.98438 6.77344 7.875 5.88281 8.41406 5.48438C9.5625 4.57031 10.3359 3.96094 10.875 3.53906V7.875H1.125Z'
				fill='#7A7A7A'
			/>
		</svg>
	);
};
