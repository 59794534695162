import {PhoneIcon} from "../../assets/icons/AppIcons/PhoneIcon";
import React from "react";
import {MailIcon} from "../../assets/icons/AppIcons/MailIcon";
import './CardDriver.scss';
import {Button} from "react-bootstrap";
import {CarTransportIcon} from "../../assets/icons/TransportIcons/CarTransportIcon";
import {TruckTransportIcon} from "../../assets/icons/TransportIcons/TruckTransportIcon";
import {BikeTransportIcon} from "../../assets/icons/TransportIcons/BikeTransportIcon";
import {HumanTransportIcon} from "../../assets/icons/TransportIcons/HumanTransportIcon";
import {CarNumberBox} from "../CarNumberBox/CarNumberBox";

export const CardDriver = (props) => {
	const data = props.data;
	return (
		<div className='card-driver-content'>
			<div className="card-driver-content-wrapper">
				<div className="card-driver-content-wrapper-transport">
					<div className="card-driver-content-wrapper-transport-icon">
						{data?.transport.transportType === 0 &&
							<CarTransportIcon fill={data?.transport.traceColor || 'none'}/>}
						{data?.transport.transportType === 1 &&
							<TruckTransportIcon fill={data?.transport.traceColor || 'none'}/>}
						{data?.transport.transportType === 2 &&
							<BikeTransportIcon fill={data?.transport.traceColor || 'none'}/>}
						{data?.transport.transportType === 3 &&
							<HumanTransportIcon fill={data?.transport.traceColor || 'none'}/>}
					</div>
					<div className='card-driver-content-wrapper-transport-license'>
						<CarNumberBox
							carNumber={
								data?.transport.licenseId
									? data.transport.licenseId
									: 'НЕ НАЗНАЧЕН'
							}
						/>
					</div>
					<div className='card-driver-content-wrapper-transport-model'>
						<span>{data?.transport.transportModel || 'Модель не указана'}</span>
						{/*<span>Мерседес-Бенц 410D</span>*/}
					</div>
				</div>
				<div className="card-driver-content-wrapper-header">
					<div className="card-driver-content-wrapper-header-photo"></div>
					<div className="card-driver-content-wrapper-header-grid">
						<label>{data?.driverName}</label>
						<div className="card-driver-content-wrapper-header-grid-status">
							В сети
						</div>
					</div>

				</div>
				<div className="card-driver-content-wrapper-footer">
					<div className="card-driver-content-wrapper-footer-contacts">
						<PhoneIcon/>
						<span>{data.phone ? '+' + data.phone : 'Не указан'}</span>
						<MailIcon className='card-driver-content-wrapper-footer-contacts-icon'/>
						<span>{data.email ? data.email : 'Не указан'}</span>
					</div>

				</div>
			</div>
			<div>
				<Button className='card-driver-content-btn' onClick={props.onClick}>
					Выбрать
				</Button>
			</div>
		</div>
	);
};
