import React from "react"
import { Badge } from "components"

import "./RadioInput.scss"

export const RadioInput = (props) => {
    return (
        <div className="wrapper-row">
            <label className={`orders-nav_item ${props.checked ? "active" : "disabled"}`}>
                <input
                    type="radio"
                    value={props.value}
                    name="orders"
                    className="radio-input"
                    checked={props.checked}
                    onChange={props.onChange}
                />
                {props.text}
            </label>
            {props.badge && <Badge active={props.checked ? true : false} counter={props.counter} />}
        </div>
    )
}
