import { useEffect, useRef, useState } from 'react';
import { Button, Card, Container, Form, Nav } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signIn } from 'utils/api/user';
import './Auth.scss';

const SignInForm = () => {
	const inputPassswordRef = useRef();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const user = useSelector((state) => state.user);

	const isLogin = useSelector((state) => state.user.isLogin);
	// const token = localStorage.getItem("AccessToken")

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	useEffect(() => {
		if (isLogin) {
			// dispatch(getAllCustomers(token))
			navigate('/routes');
		}
	}, [isLogin, navigate]);

	const handlerAuth = (e) => {
		if (e.charCode === 13) {
			dispatch(signIn(email, password));
		}
	};

	const handlerButtonAuth = () => {
		dispatch(signIn(email, password));
	};

	const handlerInput = (e) => {
		if (e.charCode === 13) {
			inputPassswordRef.current.focus();
		}
	};

	return (
		<Container
			className='d-flex justify-content-center align-items-center'
			style={{ height: window.innerHeight - 54 }}
		>
			<Card className='p-5 card'>
				<Nav className='justify-content-start flex-grow-1 pe-3 mb-5'>
					<Nav.Link
						href='/sign-in'
						className={['text-uppercase nav nav-active switch']}
					>
						Войти
					</Nav.Link>
					<Nav.Link href='sign-up' className='text-uppercase nav'>
						Зарегистрироваться
					</Nav.Link>
				</Nav>
				<Form className='d-flex flex-column'>
					<Form.Control
						className='mt-3'
						placeholder='E-mail'
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						type='text'
						onKeyPress={handlerInput}
						autoFocus
					/>
					<Form.Control
						className='mt-3'
						placeholder='Пароль'
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						type='password'
						onKeyPress={handlerAuth}
						ref={inputPassswordRef}
					/>
					{user.error && (
						<span className='mt-1 error-message'>
							Некорректно введены данные
						</span>
					)}
					<Button onClick={handlerButtonAuth} className='mt-5 uppercase button'>
						Войти
					</Button>
				</Form>
			</Card>
		</Container>
	);
};

export default SignInForm;
