import { axios } from 'core';
import { setDriver } from 'store/slices/driverSlice';

export const getAllDrivers = () => {
	let access_token = localStorage.getItem('AccessToken');
	return async (dispatch) => {
		try {
			const res = await axios.get(
				`/logistics/drivers/all`,

				{
					headers: {
						Authorization: `${access_token}`,
					},
				}
			);

			dispatch(setDriver(res.data.drivers));
		} catch (error) {
			console.log('error', error.message);
		}
	};
};

export const updateDriver = (data) => {
	let access_token = localStorage.getItem('AccessToken');
	return async (dispatch) => {
		try {
			const res = await axios.put(
				`/logistics/driver/update`,
				data,

				{
					headers: {
						Authorization: `${access_token}`,
					},
				}
			);

			if (res.status === 200) {
				dispatch(getAllDrivers());
			}
		} catch (error) {
			console.log('error', error.message);
		}
	};
};
