import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    data: [],
}

const driverSlice = createSlice({
    name: "driver",
    initialState,
    reducers: {
        setDriver(state, action) {
            state.data = action.payload
        },
        setOrderDriver(state, action) {
            state.data = action.payload
        },
    },
})

export const { setDriver, setOrderDriver } = driverSlice.actions

export default driverSlice.reducer
